import React, { useEffect, useState } from 'react'
import PageHeading from '../../../../components/PageHeading'
import ProjectImage from "../../../../assets/images/landimg.png"
import AllKhasra from './AllKhasra'
import UpdateProject from '../../../../APIs/Project/UpdateProject'
import Loader from '../../../../components/Loader'
import GetProject from '../../../../APIs/Project/GetProject'
import GetLandDetailsByProjectId from '../../../../APIs/Land/GetLandDetailsByProjectId'
export default function ProjectDetails(props) {
    let [projectData, setProjectData] = useState([])
    let [projectName, setProjectName] = useState("")
    let [landmark, setLandmark] = useState("")
    let [loader, setLoader] = useState(0)

    let handleShow = async () => {
        console.log("sxbxhsxabkxbajkvxja", JSON.parse(window.sessionStorage.getItem("ProjectData")).projectId === undefined)
        let id = JSON.parse(window.sessionStorage.getItem("ProjectData")).projectId === undefined?JSON.parse(window.sessionStorage.getItem("ProjectData")).projectData[2]:JSON.parse(window.sessionStorage.getItem("ProjectData")).projectId
        let projectData = JSON.parse(window.sessionStorage.getItem("ProjectData")).projectData !== undefined?JSON.parse(window.sessionStorage.getItem("ProjectData")).projectData:["","", 0]
        let resp = await GetLandDetailsByProjectId(id)
        if (resp.status === 1) {
            // console.log("all Land", resp.responseValue)
            window.sessionStorage.setItem("ProjectData", JSON.stringify({ "allLandData": resp.responseValue, projectData:projectData, projectId:JSON.parse(window.sessionStorage.getItem("ProjectData")).projectId !== undefined?JSON.parse(window.sessionStorage.getItem("ProjectData")).projectId:"" }))
            // navigate("/projectdashboard/")
        }
        else {

        }
    }
    let getData = () => {
        let data = JSON.parse(window.sessionStorage.getItem("ProjectData"))
        console.log("objectobjectobject", data)
        setProjectData(data)
        setProjectName(data.projectData[0])
        setLandmark(data.projectData[5])
    }


    let handleSaveForm = async () => {
        setLoader(1)
        let sendForm =
        {
            "id": projectData.projectData[2],
            "projectNo": projectData.projectData[1].slice(7, 10),
            "name": projectName,
            "landmark": landmark
        }

        let resp = await UpdateProject(sendForm)
        if (resp.status === 1) {
            alert("Updated")

            let data = JSON.parse(window.sessionStorage.getItem("ProjectData")).projectData
            let allLand = JSON.parse(window.sessionStorage.getItem("ProjectData")).allLandData
            window.sessionStorage.setItem("ProjectData", JSON.stringify({ "allLandData": allLand, "projectData": [projectName, data[1], data[2], data[3], data[4], landmark] }))
            getData()
            setLoader(0)
        }
        else {
            alert("eroor")
            setProjectName(projectData.projectData[0])
            setLoader(0)
        }
    }

    useEffect(() => {
        handleShow()
        setTimeout(()=>{
            getData()
        }, 1000)
        console.log("xwsxdbjdsbcsbyuu")
    }, [])
    return (
        <>
            {
                projectData.length !== 0 ?
                    <>
                        <div className='container-fluid landformconatiner p-4' style={{ maxHeight: "100%" }}>
                            <div className='d-flex flex-row justify-content-between'>
                                <PageHeading pagename={projectData.projectData[0]} />
                                <button className='editProject' data-bs-toggle="modal" data-bs-target="#addproject"><i class="fa-regular fa-pen-to-square fa-lg"></i>&nbsp; Edit Details</button>
                            </div>
                            <div className='row'>
                                <span className='text-secondary'>#{projectData.projectData[1]}</span>
                            </div>
                            <div className='row pt-3'>
                                <img src={ProjectImage} alt="Project" width="100%" height="100%" />
                            </div>
                            <div className='d-flex flex-row justify-content-between pt-3'>
                                <PageHeading pagename={`₹ ${projectData.projectData[3]}`} />
                                <div className='d-flex flex-row justify-content-center align-items-center gap-2'>
                                    <i class="fa-solid fa-location-arrow locationIcon"></i>
                                    <span className='locationtext'>View in Google Map</span>
                                </div>
                            </div>
                            <div className='d-flex flex-column gap-3 pt-3'>
                                <div className='d-flex flex-row  align-items-center gap-3 '>
                                    <i class="fa-solid fa-up-right-and-down-left-from-center project-icon"></i>
                                    <span className='d-flex flex-column gap-1'>
                                        <label className='heading-project'>Total Land Area</label>
                                        <label className='heading-project' style={{ color: "#737791" }}>{projectData.projectData[4]} sq</label>
                                    </span>

                                </div>
                                <div className='d-flex flex-row  align-items-center gap-3'>
                                    <i class="bi bi-geo-alt-fill project-icon"></i>
                                    <span className='d-flex flex-column gap-1'>
                                        <label className='heading-project'>Location</label>
                                        <label className='heading-project' style={{ color: "#737791" }}>{projectData.projectData[5]}</label>
                                    </span>
                                </div>
                            </div>

                            <AllKhasra setLandId={props.setLandId} />
                        </div>

                        {/* Modal  */}
                        <div className="modal  fade " id="addproject" tabindex="-1" aria-labelledby="addprojectLabel" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content pt-3 ps-3 pe-3 pb-1" style={{ maxWidth: "500px" }}>
                                    <div className="modal-header">
                                        <h1 className="modal-title fs-5 text-primary" id="addprojectLabel">Update Project</h1>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setProjectName(projectData.projectData[0]) }}></button>
                                    </div>
                                    <div className="modal-body">
                                        <div className='row pb-4'>
                                            <div className='col pb-2 pb-md-0'>
                                                <div className='d-flex flex-column gap-1'>
                                                    <label className='form-label'>Project Name</label>
                                                    <input type='text' className='form-input-box' placeholder='Enter Project Name' value={projectName} name="name" onChange={(e) => { setProjectName(e.target.value) }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row pb-4'>
                                            <div className='col pb-2 pb-md-0'>
                                                <div className='d-flex flex-column gap-1'>
                                                    <label className='form-label'>Landmark</label>
                                                    <input type='text' className='form-input-box' placeholder='Enter Landmark' value={landmark} name="landmark" onChange={(e) => { setLandmark(e.target.value) }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row '>
                                            <div className='col pb-2 pb-md-0'>
                                                <div className='d-flex flex-column gap-1'>
                                                    <label className='form-label'>Unique Id</label>
                                                    <input type='text' className='form-input-box' value={"#" + projectData.projectData[1]} disabled />
                                                </div>
                                            </div>
                                            {/* <span className='pt-1 fw-bolder'><p style={{ fontSize: "0.66rem", color: "#FE996C" }}>Unique ID generated automatically</p></span> */}
                                        </div>
                                    </div>
                                    <div className="modal-footer" >
                                        {/* <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button> */}
                                        <button type="button" style={{ width: "100%" }} className="btn btn-primary" onClick={handleSaveForm} data-bs-dismiss="modal" aria-label="Close">Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>



                    : ""
            }



            <Loader show={loader} />


        </>
    )
}

