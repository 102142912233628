import React, { useState } from 'react'
import PageHeading from '../components/PageHeading'
import AddLand from '../pages/AddLand/AddLand'
import AddProof from '../pages/AddLand/AddProof'
import AddOwner from '../pages/AddLand/AddOwner'
import AddInvestor from '../pages/AddLand/AddInvestor'

export default function LandLayout() {
    let [activetab, setActiveTab] = useState(0)
    let handleActiveTab = (val) => {
        if (JSON.parse(window.sessionStorage.getItem("ProjectData")).landData) {
            if (val === 1 && JSON.parse(window.sessionStorage.getItem("ProjectData")).landData.ownedByCategoryId !== 1) {
                setActiveTab(val)
            }
            else if (val !== 1) {
                setActiveTab(val)

            }
        }
    }
    return (
        <>
            <PageHeading pagename="Add Land Details" />
            <div className='d-flex flex-column flex-md-row gap-5 activeTabmainBox'>

                <div className={`d-flex flex-row justify-content-center align-items-center gap-2 pointer p-3 ${activetab === 0 ? "activetabBox" : ""}`} onClick={() => { handleActiveTab(0) }}>
                    <span className={`activetab ${activetab === 0 ? "selectedTab" : ""}`}></span>
                    <span className={` ${activetab === 0 ? "selectedNameTag" : ""}`}>Land Information</span>
                </div>

                {
                    JSON.parse(window.sessionStorage.getItem("ProjectData")).landData ? JSON.parse(window.sessionStorage.getItem("ProjectData")).landData.ownedByCategoryId !== 1 ?
                        <div className={`d-flex flex-row justify-content-center align-items-center gap-2 pointer p-3 ${activetab === 1 ? "activetabBox" : ""}`} onClick={() => { handleActiveTab(1) }}>
                            <span className={`activetab ${activetab === 1 ? "selectedTab" : ""}`}></span>
                            <span className={` ${activetab === 1 ? "selectedNameTag" : ""}`}>Investor</span>
                        </div>
                        : "":""
                }

                <div className={`d-flex flex-row justify-content-center align-items-center gap-2 pointer p-3 ${activetab === 2 ? "activetabBox" : ""}`} onClick={() => { handleActiveTab(2) }}>
                    <span className={`activetab ${activetab === 2 ? "selectedTab" : ""}`}></span>
                    <span className={` ${activetab === 2 ? "selectedNameTag" : ""}`}>Proof</span>

                </div>
                <div className={`d-flex flex-row justify-content-center align-items-center gap-2 pointer p-3 ${activetab === 3 ? "activetabBox" : ""}`} onClick={() => { handleActiveTab(3) }}>
                    <span className={`activetab ${activetab === 3 ? "selectedTab" : ""}`}></span>
                    <span className={` ${activetab === 3 ? "selectedNameTag" : ""}`}>Owner Details</span>

                </div>
            </div>

            <div className='d-flex flex-column pt-4 pb-5 gap-2'>
                {
                    activetab === 0 ? <AddLand handleActiveTab={handleActiveTab} /> : activetab === 1 ? <AddInvestor handleActiveTab={handleActiveTab} /> : activetab === 2 ? <AddProof handleActiveTab={handleActiveTab} /> : <AddOwner handleActiveTab={handleActiveTab} />
                }
            </div>

        </>
    )
}
