import React, { useState } from 'react'
import LoginImag from "../../assets/images/LoginImage.png"
import UserLogin from '../../APIs/Login/UserLogin';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader';
export default function ResetPassword() {

    let navigate = useNavigate()
    let [sendForm, setSendForm] = useState({
        "userName": "",
        "password": ""
    })
    let [loader, setLoader] = useState(0)

    let handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setSendForm({ ...sendForm, [name]: value })
    }

    let handleSave = async () => {
        if (sendForm.userName.trim() !== "" && sendForm.password.trim() !== "") {
            setLoader(1)
            let response = await UserLogin(sendForm)
            if (response.status === 1) {
                setLoader(0)
                window.sessionStorage.setItem("userLoginData", JSON.stringify({ token: response.token, userData: response.responseValue }))
                window.userId = response.responseValue.userId
                window.token = response.token
                window.userName = response.responseValue.name
                navigate("/allproject/")
            }
            else {
                setLoader(0)
            }
        }
    }
    return (
        <div className='container-fluid'>
            <div className='row' style={{ minHeight: "100vh" }}>
                <div className='d-none d-md-flex col-md-6 loginimage' >
                </div>
                <div className='d-flex d-flex flex-column justify-content-center align-items-center col-12 col-md-6'>
                    <div className='d-flex flex-column gap-4'>
                        <div className='d-flex flex-column justify-content-center align-items-center gap-1 pb-3'>
                            <span className='login-heading-main'>Reset Password</span>
                            <span className='login-sub-heading'>The password should have at least 8 characters.</span>
                        </div>
                        <div className='d-flex flex-column gap-4 '>
                            {/* <div className='row'>
                                <div className='col'>
                                    <div className='d-flex flex-column gap-1'>
                                        <label className='form-label'>Email</label>
                                        <div className='d-flex flex-column textboxcontainer'>
                                            <input type='email' className='form-input-box' name='userName' placeholder='Enter Your Email.' style={{ maxWidth: "650px", paddingLeft: "40px" }} onChange={handleChange} />
                                            <i className="bi bi-envelope emailicon"></i>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className='row'>
                                <div className='col'>
                                    <div className='d-flex flex-column gap-1'>
                                        <label className='form-label'>New Password</label>
                                        <div className='d-flex flex-column textboxcontainer'>
                                            <input type='password' className='form-input-box' name='password' style={{ maxWidth: "650px", paddingLeft: "40px" }} onChange={handleChange} />
                                            <i className="bi bi-lock emailicon"></i>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col'>
                                    <div className='d-flex flex-column gap-1'>
                                        <label className='form-label'>Confirm Password</label>
                                        <div className='d-flex flex-column textboxcontainer'>
                                            <input type='password' className='form-input-box' name='password' style={{ maxWidth: "650px", paddingLeft: "40px" }} onChange={handleChange} />
                                            <i className="bi bi-lock emailicon"></i>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* <div className='row'>
                                <div className='d-flex flex-column flex-md-row justify-content-between'>
                                    <div className="mb-3 form-check">
                                        <input type="checkbox" className="form-check-input checkboxlogin" id="exampleCheck1" />
                                        <label className="form-check-label passwordlabel" for="exampleCheck1">Remember me</label>
                                    </div>
                                    <span className='passwordlabel' style={{ color: "#151D48" }}>Forgot Password?</span>
                                </div>
                            </div> */}
                        </div>
                        <div className='row'>
                            <button className='btn login-btn'  onClick={handleSave}>Reset Password</button>
                        </div>
                        {/* <div className='row'>
                            <span className='passwordlabel'>or continue with email</span>
                        </div>
                        <div className='row ps-3 pe-3'>
                            <div className='d-flex flex-row justify-content-between '>
                                <div className='col-5 row'>
                                    <button className='btn login-other'>Login</button>
                                </div>

                                <div className='col-5 row'>
                                    <button className='btn login-other'>Login</button>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex flex-column gap-2 flex-md-row passwordlabel'>
                            <span>Don’t have an account?</span> <span style={{ color: "#00AFF0" }}> Create an account</span>
                        </div> */}
                    </div>
                </div>
            </div>
            <Loader show={loader}/>
        </div>
    )
}
