import React, { useEffect, useState } from 'react'
import PageHeading from '../../../../components/PageHeading'
import KhasraCard from './KhasraCard'
import { useNavigate } from 'react-router-dom'

export default function AllKhasra(props) {
    let [activetab, setActiveTab] = useState(0)
    let [allLandData, setAllLandData] = useState([])
    let navigation = useNavigate()
    let handleClick = (val) => {
        setActiveTab(val)
        props.setLandId(allLandData[val].id)
    }
    let getData = () => {
        let data = JSON.parse(window.sessionStorage.getItem("ProjectData")).allLandData
        console.log("object", data)
        if(data.length !== 0)
        {
            props.setLandId(data[0].id)
        }

        setAllLandData(data)
    }
    let handleAddLand = () => {
        let data = JSON.parse(window.sessionStorage.getItem("ProjectData"))
        window.sessionStorage.setItem("ProjectData", JSON.stringify({"allLandData":data.allLandData, "projectData":data.projectData, "projectId":data.projectData[2]} ))
        navigation("/addland/")
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <>
            {
                allLandData.length !== 0 ?

                    <>
                        <div className='d-flex flex-column pt-5'>
                            <PageHeading pagename="All Khasra" />
                            <div className='d-flex flex-column flex-md-row gap-1 activeTabmainBox' style={{ overflow: "auto" }}>

                                {
                                    allLandData && allLandData.map((val, ind) => {
                                        return (
                                            <div className={`d-flex flex-row justify-content-center align-items-center gap-2 pointer p-3 ${activetab === ind ? "activetabBox" : ""}`} onClick={() => { handleClick(ind) }}>
                                                <span className={` ${activetab === ind ? "selectedNameTag" : ""}`}>#{val.khasraNo}</span>
                                            </div>
                                        )
                                    })
                                }
                             
                            </div>

                        </div>
                        <KhasraCard allLandData={allLandData[activetab]} />
                    </> :

                    <div className='d-flex flex-row justify-content-md-end'>
                        <button className='btn export-save-btn' onClick={handleAddLand}>Add Land</button>
                    </div>
            }

        </>
    )
}
