import React, { useState } from 'react'
import ProjectSummery from './LeftSide/ProjectSummery'
import ProjectDetails from './LeftSide/ProjectDetails'
import ProjectOver from './RightSide/ProjectOver'
import ProjectExpenses from './RightSide/ProjectExpenses'
import RecentTransaction from './RightSide/RecentTransaction'
import LandOwners from './RightSide/LandOwners'

export default function ProjectDashboard() {
  let [landId, setLandId] = useState("")
  return (
    <div className='row pb-3'>
      <div className='col col-md-8'>
        <div className='d-flex flex-column gap-3'>
          <ProjectSummery />
          <ProjectDetails setLandId={setLandId}/>
        </div>
      </div>
      <div className='col col-md-4'>
        <div className='d-flex flex-column gap-3' >
          <ProjectOver />
          <ProjectExpenses />
          <RecentTransaction />
          <LandOwners landId={landId}/>
        </div>
      </div>
    </div>
  )
}
