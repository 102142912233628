export default async function GetProjectWiseInvestorPaymentDetails(id) {
    try {
        let url = window.RealestateService + `/api/PaymentDetails/GetProjectWiseInvestorPaymentDetails?projectId=${id}`
        let head = {
            "content-type": "",
            "accept": ""
        }
        let response = await fetch(url, {
            method: "GET",
            headers: head,
        }).then(res => res.json()).catch(res => res)

        return response
    }
    catch (ex){
        return {
            status:0,
            message:"",
            responseValue:[]
        }
    }
}