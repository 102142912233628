let handleOnClick = () => {
    let sidebar = document.getElementById("sidebar");
    let screenWidth = window.screen.width
    if (screenWidth >= 320 && screenWidth <= 768) {
        if (sidebar.style.display === "block") {
            sidebar.style.animation = "closesidebaranimation 1s normal  linear ";

            setTimeout(() => {
                sidebar.style.animation = ""
                sidebar.style.display = "none"
            }, 1000)
        }
        else {
            sidebar.style.animation = ""
            sidebar.style.animation = "opensidebaranimation 1s normal linear";

            sidebar.style.display = "block";
        }
    }
    else {
        sidebar.style.display="block"
        console.log("width", sidebar.clientWidth)
        if (sidebar.clientWidth > 200) {
            sidebar.style.animation = ""
            sidebar.style.animation = "closefullsidebaranimation 1s normal  linear";
            sidebar.style.width = "80px"


        }
        else {
            if (sidebar.clientWidth <= 80) {
                sidebar.style.animation = ""
                sidebar.style.animation = "openfullsidebaranimation 1s normal  linear";
                sidebar.style.width = "250px"
            }

        }
    }

}
export default handleOnClick;