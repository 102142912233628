export default async function GetLandDocumentTypeList(data) {
    let url = window.RealestateService + "/api/Land/GetLandDocumentTypeList";
    let head = {
        "content-type": "",
        "accept": ""
    }
    let response = await fetch(url, {
        method: "GET",
        headers: head,
    }).then(res => res.json()).catch(res => res)

    return response;
}