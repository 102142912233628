export default async function InsertPaymentDetails(sendForm) {
    let url = window.RealestateService+  `/api/PaymentDetails/InsertPaymentDetails`
    let head = {
        "content-type":"application/json-patch+json",
        "accept":"*/*"
    }
    let data ={}
    let response  = await fetch(url, {
        method:"POST",
        headers:head,
        body:JSON.stringify(sendForm)
    }).then(res=>res.json()).then(data)

    return response
}