export default async function PostPaymentMaster(sendForm) {
    let url = window.RealestateService + `/api/PaymentMaster/InsertPaymentMaster`;
    let head = {
        'Content-Type': 'application/json',  // Change the content type here
        'accept': '*/*',
    };
    let response = await fetch(url, {
        method: 'POST',
        headers: head,
        body: JSON.stringify(sendForm),  // Convert the sendForm to JSON
    })
    .then((res) => res.json())
    .then((data) => data);

    return response;
}
