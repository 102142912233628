import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import FilterTable from '../../components/FilterTable'
import PageHeading from '../../components/PageHeading'
import GetVendorDetails from '../../APIs/AddVendor/GetVendorDetails';

function VendorList() {
    let[vendorList,setVendorList]=useState([]);
    let[vendorTempList,setVendorTempList]=useState([]);
    let [loader, setLoader] = useState(1)

    let [sideToster, setSideToster] = useState({
        show: 0,
        message: '',
        type: ''
    })
    let getData = async () => {
        let resp = await GetVendorDetails()
        if (resp.status === 1) {
            setVendorList(resp.responseValue)
            setVendorTempList(resp.responseValue)
            setLoader(0)
        }
        else {
            setLoader(0)
        }
    }
    useEffect(() => {
        getData()
    }, [])
  return (
    <>
          <div className='container-fluid landformconatiner p-4'>
                <div className='d-flex flex-column flex-md-row justify-content-between'>
                    <PageHeading pagename="Vendor List" />
                    <FilterTable tempList={vendorTempList} tempListMethod={setVendorTempList} originalList={vendorList} isSearch={true} isdate={true} isFilter={true} isExport={true} />
                </div>
                <div className='pt-4'>
                    <table class="table">
                        <thead>
                            <tr className='table-head'>
                                {/* <th scope="col">#</th> */}
                                {/* <th scope="col">State</th> */}
                                <th scope="col">Name</th>
                                <th scope="col">Invested Amount</th>
                                <th scope="col">Location</th>
                                <th scope="col">Status</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody className='table-body'>
                            {
                                vendorTempList && vendorTempList.map((val, ind) => {
                                    return (
                                        <tr >
                                            {/* <th scope="row">{ind + 1}</th> */}
                                            <td>
                                                <div className='d-flex flex-row gap-2'>
                                                    <div style={{ width: "30px", height: "30px", backgroundColor: "#E4E4E4", borderRadius: "15px" }}></div>
                                                    <span className='d-flex flex-column gap-1'>
                                                        <label className='heading-project fw-semibold'>{val.name}</label>
                                                        {/* <label className='sub-heading-project' style={{ color: "#737791" }}>Ownership {val.percentage}%</label> */}
                                                    </span>
                                                </div>
                                            </td>
                                            <td>{val.investedAmount}</td>
                                            <td>{val.address}</td>
                                            <td style={{ color: "green" }}>{"Active"}</td>
                                            <td >
                                                <div class="dropdown dropend">
                                                    <i class="bi bi-three-dots-vertical " data-bs-toggle="dropdown" aria-expanded="false"></i>
                                                    <ul className="dropdown-menu mr-3">
                                                        {
                                                            val.investorType === 1 ?
                                                                <li><Link className="dropdown-item" to={`/viewprofilecompany/${val.id}`}>View Details</Link></li>
                                                                :
                                                                <li><Link className="dropdown-item" to={`/viewprofilecompanyvendor/${val.id}`}>View Details</Link></li>

                                                        }
                                                        {/* <li><span className="dropdown-item" onClick={() => { handleDelete(val.id) }}>Delete</span></li> */}
                                                        {/* <li onClick={handleLogout}><Link className="dropdown-item" href="#">Logout <i className="bi bi-box-arrow-right"></i></Link></li> */}
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
    </>
  )
}

export default VendorList