import React, { useEffect } from 'react'
import PageHeading from '../../../../components/PageHeading'
import KhasraImage from "../../../../assets/images/khasraimg.png"
import { useNavigate } from 'react-router-dom'
export default function KhasraCard(props) {
    let navigate = useNavigate()
    let navigation = useNavigate()

    let handleEdit = () => {
        console.log("landData",  props.allLandData)
        let data = JSON.parse(window.sessionStorage.getItem("ProjectData")).projectData
        let allLand = JSON.parse(window.sessionStorage.getItem("ProjectData")).allLandData
        window.sessionStorage.setItem("ProjectData", JSON.stringify({ "allLandData": allLand, "projectData": [data[0], data[1], data[2], data[3], data[4], data[5]], "landData": props.allLandData }))
        navigate("/addland/")
    }
    let handleAddLand = () => {
        let data = JSON.parse(window.sessionStorage.getItem("ProjectData"))
        window.sessionStorage.setItem("ProjectData", JSON.stringify({ "allLandData": data.allLandData, "projectData": data.projectData, "projectId": data.projectData[2] }))
        navigation("/addland/")
    }
  
    return (
        <>
            {
                props.allLandData !== undefined ?
                    <>
                        <div className='row pt-4'>
                            <div className='d-flex flex-row justify-content-between'>
                                <PageHeading pagename="Khasra Number" />
                                <div className='d-flex flex-row gap-1 pe-3'>

                                    <button className='editProject' onClick={handleEdit}><i class="fa-regular fa-pen-to-square fa-lg"></i>&nbsp; Edit Details</button>
                                </div>
                            </div>
                            <div className='row'>
                                <span className='text-secondary'>#{props.allLandData.khasraNo}</span>
                            </div>


                        </div>
                        <div className='row pt-4'>
                            <img src={props.allLandData.imageDetails.length !== 0? props.allLandData.imageDetails[0].fileUrl:KhasraImage} alt='img' />
                            <div className='d-flex flex-row justify-content-between align-items-center'>
                                <span className='pt-3'>
                                    <PageHeading pagename={`₹ ${props.allLandData.price}`} />
                                </span>
                                <button className='btn export-save-btn' onClick={handleAddLand}>Add Land</button>

                            </div>

                        </div>
                        <div className='d-flex flex-column gap-3 pt-3'>
                            <div className='d-flex flex-row  align-items-center gap-3 '>
                                <i class="fa-solid fa-up-right-and-down-left-from-center project-icon"></i>
                                <span className='d-flex flex-column gap-1'>
                                    <label className='heading-project'>Land Area</label>
                                    <label className='heading-project' style={{ color: "#737791" }}>{props.allLandData.landArea} sq</label>
                                </span>

                            </div>
                            <div className='d-flex flex-row  align-items-center gap-3 '>
                                <i class="bi bi-person-fill fa-up-right-and-down-left-from-center project-icon"></i>
                                <span className='d-flex flex-column gap-1'>
                                    <label className='heading-project'>Total No Of Owners</label>
                                    <label className='heading-project' style={{ color: "#737791" }}>{props.allLandData.noOfOwners}</label>
                                </span>

                            </div>
                            <div className='d-flex flex-row  align-items-center gap-3'>
                                <i class="bi bi-geo-alt-fill project-icon"></i>
                                <span className='d-flex flex-column gap-1'>
                                    <label className='heading-project'>Location</label>
                                    <label className='heading-project' style={{ color: "#737791" }}>{props.allLandData.landAddress}</label>
                                </span>
                            </div>
                            <div className='d-flex flex-row  align-items-center gap-3 '>
                                <i class="bi bi-file-earmark-image fa-up-right-and-down-left-from-center project-icon"></i>
                                <span className='d-flex flex-column gap-1'>
                                    <label className='heading-project'>Land Proof</label>
                                    {/* <label className='heading-project' style={{ color: "#737791" }}>Khata Certificate,Khata Certificate 2</label> */}
                                    <label className='heading-project' style={{ color: "#737791" }}>-</label>
                                </span>

                            </div>
                        </div>
                    </> :
                    ""
            }

        </>
    )
}
