export default async function GetLandOwnedBy(id) {
    let url = window.RealestateService+  `/api/Land/GetLandOwnedBy?landId=${id}`
    let head = {
        "content-type":"",
        "accept":""
    }
    let response = await fetch(url, {
        method:"GET",
        headers:head,
    }).then(res=>res.json()).catch(res=>res)

    return response
}