import React from 'react'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import handleOnClick from '../assets/js/Navbar'
export default function MainLayout(props) {
    return (
        <div className='conatiner-fluid'>
            <Navbar handleOnClick={handleOnClick} />
            <div className='d-flex flex-row gap-1'>
                <Sidebar handleOnClick={handleOnClick} />
                <div className='main-content ps-2 pt-4 container-fluid pe-2 ps-md-3 pe-md-3'>
                        {props.Component}
                </div>
            </div>

        </div>
    )
}
