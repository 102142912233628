import React, { useEffect, useState } from 'react'
import PageHeading from '../../../components/PageHeading'
import FilterTable from '../../../components/FilterTable'
import { Link } from 'react-router-dom'

export default function LatestExpensesTable(props) {
    let [investorList, setInvestorList] = useState([])
    let [investorTempList, setInvestorTempList] = useState([
        {
            name: "Angus MacGyver",
            date: "10 Nov 2023",
            category: "Food",
            invoice: "612262",
            paidto: "Rehan Husain",
            status: "Pending",
        },
        {
            name: "Angus MacGyver",
            date: "10 Nov 2023",
            category: "Food",
            invoice: "612262",
            paidto: "Rehan Husain",
            status: "Pending",
        },
        {
            name: "Angus MacGyver",
            date: "10 Nov 2023",
            category: "Food",
            invoice: "612262",
            paidto: "Rehan Husain",
            status: "Pending",
        },

    ])
    let [loader, setLoader] = useState(1)

    let [sideToster, setSideToster] = useState({
        show: 0,
        message: '',
        type: ''
    })

    useEffect(() => {
        setInvestorList(props.expensesData)
        setInvestorTempList(props.expensesData)
    }, [props])
    return (
        <>
            <div className='container-fluid landformconatiner p-4'>
                <div className='d-flex flex-column flex-md-row justify-content-between'>
                    <PageHeading pagename="Latest Expenses" />
                    <FilterTable tempList={investorTempList} tempListMethod={setInvestorTempList} originalList={investorList} isSearch={true} isdate={true} isFilter={true} isExport={true} />
                </div>
                <div className='pt-4'>
                    <table class="table">
                        <thead>
                            <tr className='table-head'>
                                {/* <th scope="col">#</th> */}
                                {/* <th scope="col">State</th> */}
                                <th scope="col">Name</th>
                                <th scope="col">Date</th>
                                <th scope="col">Category</th>
                                <th scope="col">Invoice</th>
                                <th scope="col">Paid to</th>
                                <th scope="col">Status</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody className='table-body'>
                            {
                                investorTempList && investorTempList.map((val, ind) => {
                                    return (
                                        <tr >
                                            {/* <th scope="row">{ind + 1}</th> */}
                                            <td>
                                                <div className='d-flex flex-row gap-2'>
                                                    <div style={{ width: "30px", height: "30px", backgroundColor: "#E4E4E4", borderRadius: "15px" }}></div>
                                                    <span className='d-flex flex-column gap-1'>
                                                        <label className='heading-project fw-semibold'>{val.name}</label>
                                                        {/* <label className='sub-heading-project' style={{ color: "#737791" }}>Ownership {val.percentage}%</label> */}
                                                    </span>
                                                </div>
                                            </td>
                                            <td>{val.date}</td>
                                            <td>{val.category}</td>
                                            <td>{val.invoice}</td>
                                            <td>{val.paidto}</td>
                                            <td style={{ color: "green" }}>{val.status}</td>
                                            <td >
                                                <div class="dropdown dropend">
                                                    <i class="bi bi-three-dots-vertical " data-bs-toggle="dropdown" aria-expanded="false"></i>
                                                    <ul className="dropdown-menu mr-3">
                                                        {
                                                            val.investorType === 1 ?
                                                                <li><Link className="dropdown-item" to={`/viewprofilecompany/${val.id}`}>View Details</Link></li>
                                                                :
                                                                <li><Link className="dropdown-item" to={`/viewprofileinvestor/${val.id}`}>View Details</Link></li>

                                                        }
                                                        {/* <li><span className="dropdown-item" onClick={() => { handleDelete(val.id) }}>Delete</span></li> */}
                                                        {/* <li onClick={handleLogout}><Link className="dropdown-item" href="#">Logout <i className="bi bi-box-arrow-right"></i></Link></li> */}
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            }



                        </tbody>
                    </table>
                </div>


            </div>
        </>
    )
}
