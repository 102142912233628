import React, { useEffect, useState } from 'react'
import PageHeading from '../../../components/PageHeading'
import { useNavigate, useParams } from 'react-router-dom'
import GetInvestorDetailsById from '../../../APIs/AddInvestor/GetInvestorDetailsById'

export default function ViewProfileIndividualInvestor() {

    let id = useParams()
    let navigate = useNavigate()
    let [investordata, setInvestorData] = useState([])
    let getData = async (id) => {

        let resp = await GetInvestorDetailsById(id)
        if (resp.status === 1) {
            setInvestorData(resp.responseValue[0])
            // console.log("data", resp.responseValue[0])
        }
    }
    useEffect(() => {
        getData(id.id)
    }, [])

    let handleEdit = () => {
        navigate("/saveindividualinvestor/"+id.id)
    }
    return (
        <>
            {
                investordata.length !== 0 ?
                    <>

                        <div className='container-fluid landformconatiner p-4'>
                            <div className='d-flex flex-row justify-content-between'>
                                <PageHeading pagename="Investor Details" />
                                <button className='editProject' onClick={handleEdit}><i class="fa-regular fa-pen-to-square fa-lg"></i>&nbsp; Edit Details</button>
                            </div>
                            <div className='row'>
                                <span className='text-secondary'>#2156460511</span>
                            </div>
                            <div className='d-flex flex-row gap-2 mt-3'>
                                <div style={{ width: "40px", height: "40px", backgroundColor: "#03D4C6", borderRadius: "20px" }}></div>
                                <span className='d-flex flex-column gap-1 pt-1 ps-2'>
                                    <label className='heading-project fw-semibold'>{investordata.name}</label>
                                    <label className='heading-project' style={{ color: "#737791" }}>{investordata.address}</label>
                                </span>
                            </div>

                        </div>

                        <div className='container-fluid landformconatiner mt-2 p-4'>
                            <div className='d-flex flex-row justify-content-between'>
                                <PageHeading pagename="Personal Details" />
                            </div>
                            <div className='wrapform pt-3'>
                                <div className='d-flex flex-column gap-1'>
                                    <label className='form-label'>Your Name</label>
                                    <span className='text-secondary'>{investordata.name}</span>
                                </div>
                                <div className='d-flex flex-column gap-1'>
                                    <label className='form-label'>Email Address</label>
                                    <span className='text-secondary'>{investordata.emailId}</span>
                                </div>
                                {/* <div className='d-flex flex-column gap-1'>
                                    <label className='form-label'>Invested in Any Property</label>
                                    <span className='text-secondary'>#2156460511</span>
                                </div> */}

                                <div className='d-flex flex-column gap-1'>
                                    <label className='form-label'>Mobile No.</label>
                                    <span className='text-secondary'>{investordata.mobileNo}</span>
                                </div>
                                <div className='d-flex flex-column gap-1'>
                                    <label className='form-label'>Select State</label>
                                    <span className='text-secondary'>{investordata.stateName}</span>
                                </div>
                                <div className='d-flex flex-column gap-1'>
                                    <label className='form-label'>Select City</label>
                                    <span className='text-secondary'>{investordata.cityName}</span>
                                </div>

                                <div className='d-flex flex-column gap-1'>
                                    <label className='form-label'>Pin Code</label>
                                    <span className='text-secondary'>{investordata.pincode}</span>
                                </div>
                                <div className='d-flex flex-column gap-1'>
                                    <label className='form-label'>Address</label>
                                    <span className='text-secondary'>{investordata.address}</span>
                                </div>
                                {/* <div className='d-flex flex-column gap-1'>
                                    <label className='form-label'>Unique Id if you have</label>
                                    <span className='text-secondary'>#2156460511</span>
                                </div> */}
                            </div>

                        </div>


                        <div className='container-fluid landformconatiner mt-2 p-4'>
                            <div className='d-flex flex-row justify-content-between'>
                                <PageHeading pagename="Bank Details" />
                            </div>
                            <div className='wrapform pt-3'>
                                <div className='d-flex flex-column gap-1'>
                                    <label className='form-label'>Bank Name</label>
                                    <span className='text-secondary'>{investordata.bankDetails.bankName}</span>
                                </div>
                                <div className='d-flex flex-column gap-1'>
                                    <label className='form-label'>Account Holder's Name</label>
                                    <span className='text-secondary'>{investordata.bankDetails.accountHolderName}</span>
                                </div>
                                <div className='d-flex flex-column gap-1'>
                                    <label className='form-label'>Branch Code</label>
                                    <span className='text-secondary'>{investordata.bankDetails.branchCode}</span>
                                </div>

                                <div className='d-flex flex-column gap-1'>
                                    <label className='form-label'>Bank Account No</label>
                                    <span className='text-secondary'>{investordata.bankDetails.accountNo}</span>
                                </div>
                                <div className='d-flex flex-column gap-1'>
                                    <label className='form-label'>IFSC Code</label>
                                    <span className='text-secondary'>{investordata.bankDetails.ifscCode}</span>
                                </div>

                            </div>

                        </div>


                        <div className='container-fluid landformconatiner mt-2 p-4'>
                            <div className='d-flex flex-row justify-content-between'>
                                <PageHeading pagename="Documents" />
                            </div>
                            <div className='wrapform pt-3'>
                                <div className='d-flex flex-column gap-1'>
                                    <label className='form-label'>Document Type</label>
                                    {/* <span className='text-secondary'>#2156460511</span> */}
                                    <div className='d-flex flex-row gap-3' style={{overflowX:"auto", width:"90%"}}>
                                        {
                                            investordata.documents.length &&
                                            investordata.documents.map((val, ind) => {
                                                return (<>
                                                    <img src={val.image} alt='document' />
                                                </>)
                                            })

                                        }
                                    </div>
                                </div>


                            </div>

                        </div>
                    </>

                    : ""
            }


        </>
    )
}
