import React, { useEffect, useState } from 'react'
import Summerybox from '../../../components/Summerybox'
import PageHeading from '../../../components/PageHeading'
import FilterTable from '../../../components/FilterTable'
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { Link, useNavigate, useParams } from 'react-router-dom';
import GetInvestorTransactionDashboardData from '../../../APIs/Accounting/Investor/GetInvestorTransactionDashboardData';
import SendAmount from './SendAmount';
import Loader from '../../../components/Loader';

export default function InvestorPortfolioOverview() {
    let [overviewData, setOverviewData] = useState([])
    let [transactionTempList, setTransactionTempList] = useState([])
    let [tempTransactionTempList, setTempTransactionTempList] = useState([])
    let [investmentList, setInvestmentList] = useState([])
    let [pendingAmount, setPendingAmount] = useState(0)
    let { investorName,investorId, typeId } = useParams();
    let [loader, setLoader] = useState(1)

    let navigate = useNavigate()
    let getData = async () => {
        setLoader(1)
        let response = await GetInvestorTransactionDashboardData(investorId, typeId)
        if (response.status === 1) {
            setLoader(0)
            let data = response.responseValue
            setPendingAmount(data.portfolioOverview.investedAmount - data.portfolioOverview.givenAmount)
            setOverviewData(data.portfolioOverview)
            setInvestmentList(data.landAreaDetails)
            if (data.transactions.length !== 0) {
                setTempTransactionTempList(data.transactions)
                setTransactionTempList(data.transactions)
            }
            else {
                setTempTransactionTempList([])
                setTransactionTempList([])
            }
        }
        else {
            setLoader(0)
        }
    }
    useEffect(() => {
        getData()
    }, [])
    const options = {
        chart: {
            type: 'pie',
            margin: [0, 0, 0, 0],
            spacingTop: 0,
            spacingBottom: 0,
            spacingLeft: 0,
            spacingRight: 0,
            height: '250px'
        },
        title: {
            verticalAlign: 'middle',
            floating: true,
            // text: "Total Investment <br/>" + (totalInvestment / 10000000).toFixed(2) + "Cr."
            text: "Total Area <br/>" + investmentList.totalArea
        },
        plotOptions: {
            pie: {
                innerSize: '70%',
                size: "100%",
                dataLabels: {
                    enabled: false,
                },
            },
            borderRadius: 600,
        },
        series: [
            {
                name: 'Invested Amount',
                data: [
                    { name: "Given Amount", y: overviewData.givenAmount, },
                    { name: "Send or Receive Amount", y: overviewData.sentReceivedAmount, },
                    { name: "Pending Amount", y: overviewData.investedAmount, }
                ],
            },
        ],


    };
    return (
        <>
            <div className='row pb-3'>
                <div className='col col-md-8'>
                    <div className='d-flex flex-column gap-3'>
                        <div className='container-fluid landformconatiner p-4' style={{ maxHeight: "420px" }}>
                            {/* <PageHeading pagename="Expenses Overview" /> */}
                            <div className='d-flex flex-column flex-md-row justify-content-between pe-3 ps-3 pb-3'>
                                <PageHeading pagename={`${investorName} Portfolio Overview`} />
                                <button className="btn btn-primary" type="button" class="btn btn-primary"  data-bs-toggle="modal" data-bs-target="#exampleModal">+ Send Amount</button>
                            </div>
                            <div className='expenses-conatiner' style={{ columnGap: "25px" }}>
                                <Summerybox color="#006CFF" title="Pending Amount" number={overviewData && (overviewData.investedAmount - overviewData.givenAmount)} subheading="" />
                                <Summerybox color="#03D4C6" title="Given Amount" money={overviewData && overviewData.givenAmount} subheading="" />
                                <Summerybox color="#FE996C" title="Send or Receive Amount" money={overviewData && overviewData.sentReceivedAmount} subheading="" />
                            </div>
                        </div>

                        {/* Table */}
                        <div className='container-fluid landformconatiner p-4'>
                            <div className='d-flex flex-column flex-md-row justify-content-between'>
                                <PageHeading pagename="Transactions Info" />
                                <FilterTable tempList={tempTransactionTempList} tempListMethod={setTransactionTempList} originalList={transactionTempList} isSearch={true} isdate={true} isFilter={true} isExport={true} />
                            </div>
                            <div className='pt-4'>
                                <table class="table">
                                    <thead>
                                        <tr className='table-head'>
                                            {/* <th scope="col">#</th> */}
                                            {/* <th scope="col">State</th> */}
                                            <th scope="col">Amount</th>
                                            <th scope="col">Date</th>
                                            <th scope="col">Transactions type</th>
                                            <th scope="col">Category</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody className='table-body'>
                                        {
                                            tempTransactionTempList && tempTransactionTempList.map((val, ind) => {
                                                return (
                                                    <tr >
                                                        {/* <th scope="row">{ind + 1}</th> */}
                                                        {/* <td>
                                                            <div className='d-flex flex-row gap-2'>
                                                                <div style={{ width: "30px", height: "30px", backgroundColor: "#E4E4E4", borderRadius: "15px" }}></div>
                                                                <span className='d-flex flex-column gap-1'>
                                                                    <label className='heading-project fw-semibold'>{val.investorName}</label>
                                                                </span>
                                                            </div>
                                                        </td> */}
                                                        <td>{val.amount}</td>
                                                        <td>{val.transactionDate}</td>
                                                        <td>{val.transactionstype}</td>
                                                        <td></td>
                                                        <td >
                                                            <div class="dropdown dropend">
                                                                <i class="bi bi-three-dots-vertical " data-bs-toggle="dropdown" aria-expanded="false"></i>
                                                                <ul className="dropdown-menu mr-3">
                                                                    {
                                                                        val.investorType === 1 ?
                                                                            <li><Link className="dropdown-item" to={`/viewprofilecompany/${val.id}`}>View Details</Link></li>
                                                                            :
                                                                            <li><Link className="dropdown-item" to={`/viewprofileinvestor/${val.id}`}>View Details</Link></li>

                                                                    }
                                                                    {/* <li><span className="dropdown-item" onClick={() => { handleDelete(val.id) }}>Delete</span></li> */}
                                                                    {/* <li onClick={handleLogout}><Link className="dropdown-item" href="#">Logout <i className="bi bi-box-arrow-right"></i></Link></li> */}
                                                                </ul>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }



                                    </tbody>
                                </table>
                            </div>


                        </div>

                    </div>
                </div>
                <div className='col col-md-4'>
                    <div className='d-flex flex-column gap-3' >
                        <div className='container-fluid landformconatiner p-4'>


                            <div className='row pt-3 mt-2' style={{ height: "295px" }}>
                                <HighchartsReact highcharts={Highcharts} options={options} />
                            </div>

                            <div className='d-flex flex-column gap-3 pt-5 mt-2'>

                                <div className='d-flex flex-row justify-content-between pb-2 rowBox' >
                                    <div className='d-flex flex-row gap-2'>
                                        <div className='projecticon' style={{ backgroundColor: "#3B82F6" }}>
                                            <svg width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M2 0C0.895432 0 0 0.895447 0 2V16C0 17.1046 0.895432 18 2 18H16C17.1046 18 18 17.1046 18 16V2C18 0.895447 17.1046 0 16 0H2ZM5 10C5 9.44769 4.55228 9 4 9C3.44772 9 3 9.44769 3 10V14C3 14.5523 3.44772 15 4 15C4.55228 15 5 14.5523 5 14V10ZM9 6C9.55228 6 10 6.44769 10 7V14C10 14.5523 9.55228 15 9 15C8.44772 15 8 14.5523 8 14V7C8 6.44769 8.44772 6 9 6ZM15 4C15 3.44769 14.5523 3 14 3C13.4477 3 13 3.44769 13 4V14C13 14.5523 13.4477 15 14 15C14.5523 15 15 14.5523 15 14V4Z" fill={"rgba(255, 255, 255, 1)"} />
                                            </svg>

                                        </div>
                                        <span className='d-flex flex-column '>
                                            <label className='heading-project fw-semibold'>Land Area</label>
                                            {/* <label className='sub-heading-project' >Online Sell</label> */}
                                        </span>
                                    </div>
                                    <div className='d-flex flex-row'>
                                        <PageHeading pagename={`${investmentList.totalArea} Sqf`} />
                                    </div>
                                </div>
                                <div className='d-flex flex-row justify-content-between pb-2 rowBox' >
                                    <div className='d-flex flex-row gap-2'>
                                        <div className='projecticon' style={{ backgroundColor: "#3B82F6" }}>
                                            <svg width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M2 0C0.895432 0 0 0.895447 0 2V16C0 17.1046 0.895432 18 2 18H16C17.1046 18 18 17.1046 18 16V2C18 0.895447 17.1046 0 16 0H2ZM5 10C5 9.44769 4.55228 9 4 9C3.44772 9 3 9.44769 3 10V14C3 14.5523 3.44772 15 4 15C4.55228 15 5 14.5523 5 14V10ZM9 6C9.55228 6 10 6.44769 10 7V14C10 14.5523 9.55228 15 9 15C8.44772 15 8 14.5523 8 14V7C8 6.44769 8.44772 6 9 6ZM15 4C15 3.44769 14.5523 3 14 3C13.4477 3 13 3.44769 13 4V14C13 14.5523 13.4477 15 14 15C14.5523 15 15 14.5523 15 14V4Z" fill={"rgba(255, 255, 255, 1)"} />
                                            </svg>

                                        </div>
                                        <span className='d-flex flex-column '>
                                            <label className='heading-project fw-semibold'>Per Squire Feet cost</label>
                                            {/* <label className='sub-heading-project' >Online Sell</label> */}
                                        </span>
                                    </div>
                                    <div className='d-flex flex-row'>
                                        <PageHeading pagename={`₹ ${investmentList.costPerSquareFeet} `} />
                                    </div>
                                </div>
                                <div className='d-flex flex-row justify-content-between pb-2 rowBox' >
                                    <div className='d-flex flex-row gap-2'>
                                        <div className='projecticon' style={{ backgroundColor: "#3B82F6" }}>
                                            <svg width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M2 0C0.895432 0 0 0.895447 0 2V16C0 17.1046 0.895432 18 2 18H16C17.1046 18 18 17.1046 18 16V2C18 0.895447 17.1046 0 16 0H2ZM5 10C5 9.44769 4.55228 9 4 9C3.44772 9 3 9.44769 3 10V14C3 14.5523 3.44772 15 4 15C4.55228 15 5 14.5523 5 14V10ZM9 6C9.55228 6 10 6.44769 10 7V14C10 14.5523 9.55228 15 9 15C8.44772 15 8 14.5523 8 14V7C8 6.44769 8.44772 6 9 6ZM15 4C15 3.44769 14.5523 3 14 3C13.4477 3 13 3.44769 13 4V14C13 14.5523 13.4477 15 14 15C14.5523 15 15 14.5523 15 14V4Z" fill={"rgba(255, 255, 255, 1)"} />
                                            </svg>

                                        </div>
                                        <span className='d-flex flex-column '>
                                            <label className='heading-project fw-semibold'>Land papers</label>
                                            {/* <label className='sub-heading-project' >Online Sell</label> */}
                                        </span>
                                    </div>
                                    <div className='d-flex flex-row'>
                                        {/* <PageHeading pagename={``} /> */}
                                        <div className='d-flex flex-row justify-content-between pb-2 ' >
                                            <Link to="" style={{ fontWeight: "600", fontSize: "1rem", lineHeight: "24px" }}>View More</Link>
                                        </div>
                                    </div>
                                </div>




                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SendAmount investorName={investorName} investorId={investorId} typeId={typeId} pendingAmount={pendingAmount}/>
            <Loader show={loader} />

        </>
    )
}
