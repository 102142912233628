import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import O2Auth from './O2Auth';

export default function Authentication(props) {
    let [Component, setComponent] = useState(<></>)
    // let Component = props.Component;
    let navigate = useNavigate();
    useEffect(() => {
        let token = O2Auth()
        console.log("token", token)
        if (token === false) {
            navigate("/")
        }
        else{
            setComponent(props.Component)
        }
    }, [props])
    return (
        <>
            {Component}
        </>

    )
}
