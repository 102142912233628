import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import PageHeading from '../../../components/PageHeading'
import Summerybox from '../../../components/Summerybox'
import GetInvestorOverview from '../../../APIs/Accounting/Investor/GetInvestorOverview'

export default function InvestorExpensesOverview() {
  let navigate = useNavigate()

  let [overviewData, setOverviewData] = useState([])
  let getData = async () => {
    let response = await GetInvestorOverview()
    if (response.status === 1) {
      setOverviewData(response.responseValue)
    }
    else {
      setOverviewData([])
    }
  }
  useEffect(() => {
    getData()
  }, [])
  return (
    <>
      <div className='container-fluid landformconatiner p-4' style={{ maxHeight: "420px" }}>
        {/* <PageHeading pagename="Expenses Overview" /> */}
        <div className='d-flex flex-column flex-md-row justify-content-between pe-3 ps-3 pb-3'>
          <PageHeading pagename="Investor Overview" />
          <button className="btn btn-primary" type="button" class="btn btn-primary" onClick={() => { navigate("") }}>+ Create Entry</button>
        </div>
        <div className='expenses-conatiner' style={{ columnGap: "25px" }}>
          {/* <Summerybox color="#006CFF" title="Total Investors" number={overviewData && overviewData.totalInvestors} subheading="+ 10% from last month" />
          <Summerybox color="#03D4C6" title="Investment Portfolio" money={overviewData && overviewData.investmentPortfolio} subheading="+5% from last month" />
          <Summerybox color="#FE996C" title="Interest Amount" money={overviewData && overviewData.interestAmount} subheading="- 8% from  last month" /> */}


          <Summerybox color="#006CFF" title="Total Investors" number={overviewData && overviewData.totalInvestors} subheading="" />
          <Summerybox color="#03D4C6" title="Investment Portfolio" money={overviewData && overviewData.investmentPortfolio} subheading="" />
          <Summerybox color="#FE996C" title="Interest Amount" money={overviewData && overviewData.interestAmount} subheading="" />
        </div>
      </div>
    </>
  )
}
