import React, { useEffect, useState } from 'react'
import InvestorExpenses from './InvestorExpenses'
import InvestorExpensesOverview from './InvestorExpensesOverview'
import CompareBarGraph from '../../../components/CompareBarGraph'
import InvestorsTransactionsTable from './InvestorsTransactionsTable'
import GetInvestmentSummary from '../../../APIs/Accounting/Investor/GetInvestmentSummary'
import Loader from '../../../components/Loader'

export default function InvestorOverviewIndex() {

  let [salePurchase, setSalePurchase] = useState(["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July"])
  let [salePurchaseData, setSalePurchaseData] = useState([])
  let [projectId, setProjectId] = useState(0)
  let [DaysId, setDaysId] = useState(1)
  let [loader, setLoader] = useState(1)

  let getData = async () => {
    setLoader(1)
    let response = await GetInvestmentSummary(projectId, DaysId)
    if (response.status === 1) {
      setLoader(0)
      if (response.responseValue.length !== 0) {
        let temprecive = []
        let tempgiven = []
        let period = []
        response.responseValue.map((val) => {

          tempgiven.push((val.given / 10000000).toFixed(2))
          temprecive.push((val.received / 10000000).toFixed(2))

          period.push(val.period)
        })

        setSalePurchaseData([
          {
            label: 'Received',
            barPercentage: 0.3,
            backgroundColor: '#40BE54',
            data: temprecive,
            barThickness: 10

          }, {
            label: 'Given',
            barPercentage: 0.3,
            backgroundColor: '#52A1FD',
            data: tempgiven,
            barThickness: 10

          },

        ])
        setSalePurchase(period)
      }
      else {
        setLoader(0)
        setSalePurchaseData([])
        setSalePurchase([])
      }
    }
    else {
      setLoader(0)
      setSalePurchaseData([])
      setSalePurchase([])
    }
   
  }

  useEffect(() => {
    getData()

  }, [projectId, DaysId])
  return (
    <>
      <div className='row pb-3'>
        <div className='col col-md-8'>
          <div className='d-flex flex-column gap-3'>
            <InvestorExpensesOverview />
            <CompareBarGraph title="Investment Summary &nbsp; (in Crore)" datalabel={salePurchase} dataArray={salePurchaseData} yLabel="Price" xLabel="Months" setDaysId={setDaysId} />

          </div>
        </div>
        <div className='col col-md-4'>
          <div className='d-flex flex-column gap-3' >
            <InvestorExpenses setProjectId={setProjectId} />
          </div>
        </div>
      </div>
      <div className='row ps-3 pe-3 '>
        <InvestorsTransactionsTable projectId={projectId} />
      </div>
      <Loader show={loader} />
    </>
  )
}
