import React from 'react'
import PageHeading from '../../../../components/PageHeading'
import BoxContainer from './BoxContainer'

export default function RevenueSummery() {
  return (
    <>
      <div className='container-fluid landformconatiner p-4' >
        <div className='d-flex flex-column gap-2' >
          <div className='d-flex flex-column justify-content-between'>
            <PageHeading pagename="Revenue Summery" />
            {/* <div className='d-flex flex-row gap-4'> */}
            <div className='d-flex flex-column gap-3'>
              <BoxContainer price={"$300K"} heading={"Total Income"} sub_heading={"+ 10% increase from last month"} boxcolor={"rgba(255, 84, 172, 0.2)"} iconcolor={"rgba(255, 84, 172, 1)"} />
              <BoxContainer price={"$200K"} heading={"Property Expenses"} sub_heading={"- 5% down from last month"} boxcolor={"rgba(97, 152, 253, 0.2)"} iconcolor={"rgba(97, 152, 253, 0.2)"} />
              <BoxContainer price={"$10K"} heading={"Office Expenses"} sub_heading={"- 5% down from last month"} boxcolor={"rgba(255, 173, 95, 0.2)"} iconcolor={"rgba(255, 173, 95, 1)"} />
              <BoxContainer price={"$90K"} heading={"Total Profit"} sub_heading={"+ 20%  increase from last month"} boxcolor={"rgba(62, 64, 149, 0.15)"} iconcolor={"rgba(62, 64, 149, 1)"} />
              <BoxContainer price={"$90K"} heading={"Total Profit"} sub_heading={"+ 20%  increase from last month"} boxcolor={"rgba(62, 64, 149, 0.15)"} iconcolor={"rgba(62, 64, 149, 1)"} />
              <BoxContainer price={"$90K"} heading={"Total Profit"} sub_heading={"+ 20%  increase from last month"} boxcolor={"rgba(62, 64, 149, 0.15)"} iconcolor={"rgba(62, 64, 149, 1)"} />
             
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>
    </>
  )
}
