export default async function DeleteProject(id) {
    let url = window.RealestateService + "/api/Project/DeleteProject?id="+id
    let response = {}

    let head = {
        "Content-Type": "application/json",
        'accept': '*/*'
    }
    response = await fetch(url, {
        method: "DELETE",
        headers: head,
        // body: JSON.stringify(senddata)
    }).then(res => res.json()).then(response)
    return response;
}