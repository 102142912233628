import React, { useEffect, useRef, useState } from 'react'
import SideMessage from '../../../components/SideMessage'
import Loader from '../../../components/Loader'
import FileUpload from '../../../components/FileUpload'
import GetStates from '../../../APIs/Master/StateMaster/GetStates'
import GetIdentityDocumentTypeList from '../../../APIs/Master/IdentityDocumentTypeMaster/GetIdentityDocumentTypeList'
import GetLandDocumentTypeList from '../../../APIs/Master/LandDocumentTypeMaster/GetLandDocumentTypeList'
import GetBankMaster from '../../../APIs/Bank/GetBankMaster'
import GetVendorDetailsById from '../../../APIs/AddVendor/GetVendorDetailsById'
import GetCitiesByStateId from '../../../APIs/Master/CityMaster/GetCitiesByStateId'
import InsertVendorDetails from '../../../APIs/AddVendor/InsertVendorDetails'
import InsertVendorBankDocuments from '../../../APIs/AddVendor/InsertVendorBankDocuments'
import { useNavigate, useParams } from 'react-router-dom'
import UpdateVendorDetails from '../../../APIs/AddVendor/UpdateVendorDetails'
import PageHeading from '../../../components/PageHeading'
import InputBoxNumber from '../../../components/InputBoxNumber'

export default function InsertIndividualVendor() {
    let [sendForm, setSendForm] = useState({
        "vendorType": -1,
        "name": "",
        "emailId": "",
        "mobileNo": "",
        "pincode": "",
        "address": "",
        "stateId": "-1",
        "cityId": "-1",
        "vendorBankDetails": {
            "bankId": "-1",
            "vendorId": -1,
            "accountHolderName": "",
            "branchCode": "",
            "accountNo": "",
            "ifscCode": "",
            "userId": window.userId,

        },
        "userId": window.userId,
    })

    let [currentIndex, setCurrentIndex] = useState(1)
    const currentIndexRef = useRef(0);
    let [sideToster, setSideToster] = useState({
        show: 0,
        message: '',
        type: ''
    })
    let [stateList, setStateList] = useState([])
    let [cityList, setCityList] = useState([])
    let [bankList, setBankList] = useState([])
    let [identityTypeList, setIdentityTypeList] = useState([])
    let [allImage, setAllImage] = useState([])
    let [landDocumentTypeList, setLandDocumentTypeList] = useState([])
    let [documentTypeId, setDocumentTypeId] = useState(0)
    let [loader, setLoader] = useState(1)
    let [isUpdate, setIsUpdate] = useState(0)
    let navigate = useNavigate()


    let clear = () => {
        setSendForm({
            "vendorType": -1,
            "name": "",
            "emailId": "",
            "mobileNo": "",
            "pincode": "",
            "address": "",
            "stateId": "-1",
            "cityId": "-1",
            "vendorBankDetails": {
                "bankId": "-1",
                "vendorId": -1,
                "accountHolderName": "",
                "branchCode": "",
                "accountNo": "",
                "ifscCode": "",
                "userId": window.userId,

            },
            "userId": window.userId,
        })
    }
    let getData = async () => {
        let respState = await GetStates()
        let respIdentityType = await GetIdentityDocumentTypeList()
        let respLandProof = await GetLandDocumentTypeList()
        let respBank = await GetBankMaster()

        if (respState.status === 1) {
            setStateList(respState.responseValue)
        }
        if (respIdentityType.status === 1) {
            setIdentityTypeList(respIdentityType.responseValue)
        }
        if (respLandProof.status === 1) {
            setLandDocumentTypeList(respLandProof.responseValue)
        }
        if (respBank.status === 1) {
            setBankList(respBank.responseValue)
            setLoader(0)
        }

    }
    let getVendordata = async (id) => {

        let resp = await GetVendorDetailsById(id)
        if (resp.status === 1) {
            let temp = resp.responseValue[0]
            let banck = { ...temp.bankDetails }
            delete temp.bankDetails
            let images = temp.documents
            delete temp.documents

            let Vendor = {
                ...temp
            }
            getCity(Vendor.stateId)
            setSendForm({ ...Vendor, ["vendorBankDetails"]: banck })
            setAllImage(images)
            console.log("data", images)
        }
    }


    let getCity = async (id) => {
        let respCity = await GetCitiesByStateId(id);
        if (respCity.status === 1) {

            setCityList(respCity.responseValue)
        }
    }
    let handleChnage = (e) => {
        let name = e.target.name
        let value = e.target.value
        if (name === "stateId") {
            if (value !== "-1") {
                getCity(value)
                setSendForm({ ...sendForm, [name]: value })
            }
            else {
                getCity(value)
                setSendForm({ ...sendForm, [name]: value, "cityId": "-1" })
            }


        }
        else if (name === "bankId") {

            let temp = { ...sendForm }
            temp["vendorBankDetails"][name] = value


            setSendForm({ ...sendForm })


        }
        else if (name === "vendorId") {

            let temp = { ...sendForm }
            temp["vendorBankDetails"][name] = value
            setSendForm({ ...sendForm })


        }

        else if (name === "accountHolderName") {

            let temp = { ...sendForm }
            temp["vendorBankDetails"][name] = value
            setSendForm({ ...sendForm })

        }
        else if (name === "branchCode") {

            let temp = { ...sendForm }
            temp["vendorBankDetails"][name] = value
            setSendForm({ ...sendForm })

        }
        else if (name === "accountNo") {

            let temp = { ...sendForm }
            temp["vendorBankDetails"][name] = value
            setSendForm({ ...sendForm })

        }
        else if (name === "ifscCode") {

            let temp = { ...sendForm }
            temp["vendorBankDetails"][name] = value
            setSendForm({ ...sendForm })

        }
        else {

            setSendForm({ ...sendForm, [name]: value })
        }
    }

    let handleSave = async () => {
        let resp = validation()
        if (resp[0]) {
            setLoader(1)
            sendForm["vendorType"] = 0
            let resp = await InsertVendorDetails(sendForm)
            if (resp.status === 1) {
                let id = resp.responseValue.vendorBankDetails.id
                let formData = new FormData()
                formData.append("VendorBankId", id)
                formData.append("DocumentType", documentTypeId)
                formData.append("UserId", window.userId)
                allImage.map((val, ind) => {
                    formData.append("formFiles", val);
                })

                let response = await InsertVendorBankDocuments(formData)
                if (response.status === 1) {
                    setLoader(0)
                    setSideToster({
                        show: 1,
                        message: 'Data Save',
                        type: 'Success'
                    })
                    clear()
                    navigate("/individualvendor/")

                }

            }
            else {
                setLoader(0)
                setSideToster({
                    show: 1,
                    message: resp.responseValue,
                    type: 'Alert'
                })
            }

        }
        else {

            setSideToster({
                show: 1,
                message: resp[1],
                type: "Alert"
            })

        }
        // console.log("check ", resp)
    }

    const handleAddImage = () => {
        setAllImage(prevAllImage => {
            const newImage = {
                data: [],
                setData: (images) => {
                    setAllImage(prevAllImage => {
                        const updatedAllImage = [...prevAllImage];
                        updatedAllImage[currentIndex].data = images;
                        return updatedAllImage;
                    });
                },
            };

            // Use the updated index
            setCurrentIndex(prevIndex => prevIndex + 1);

            return [...prevAllImage, newImage];
        });
    };

    let validation = () => {
        if (sendForm.name.trim() !== "" && sendForm.emailId.trim() !== "" && sendForm.mobileNo.trim().length === 10 && sendForm.pincode.trim().length === 6 && sendForm.address.trim() !== "" && sendForm.stateId.toString().trim() !== "-1" && sendForm.cityId.toString().trim() !== "-1" && sendForm.vendorBankDetails.bankId.toString().trim() !== "-1" && sendForm.vendorBankDetails.accountHolderName.trim() !== "" && sendForm.vendorBankDetails.branchCode.trim() !== "" && sendForm.vendorBankDetails.accountNo.trim() !== "" && sendForm.vendorBankDetails.ifscCode.trim() !== "") {
            return [true, ""]
        }
        else if (sendForm.name.trim() === "") {
            return [false, "Please Enter Vendor Name"]
        }
        else if (sendForm.emailId.trim() === "") {
            return [false, "Please Enter Vendor Email Id"]
        }
        else if (sendForm.mobileNo.trim() !== 10) {
            return [false, "Please Enter Vendor Mobile No."]
        }
        else if (sendForm.pincode.trim() !== 6) {
            return [false, "Please Enter Pincode"]
        }
        else if (sendForm.address.trim() === "") {
            return [false, "Please Enter Vendor Address"]
        }
        else if (sendForm.stateId.toString().trim() === "-1") {
            return [false, "Please Select State"]
        }
        else if (sendForm.cityId.toString().trim() === "-1") {
            return [false, "Please Select City"]
        }
        else if (sendForm.vendorBankDetails.bankId.toString().trim() === "-1") {
            return [false, "Please Select Bank"]
        }
        else if (sendForm.vendorBankDetails.accountHolderName.trim() === "") {
            return [false, "Please Enter Account Holder Name"]
        }
        else if (sendForm.vendorBankDetails.branchCode.trim() === "") {
            return [false, "Please Enter Bank Branch Code"]
        }
        else if (sendForm.vendorBankDetails.accountNo.trim() === "") {
            return [false, "Please Enter Vendor Account No"]
        }
        else if (sendForm.vendorBankDetails.ifscCode.trim() === "") {
            return [false, "Please Enter Vendor IFSC Code"]
        }
        else {
            return [false, "Somthing Wrong!!"]
        }
    }

    let handleUpdate = async () => {
        let resp = validation()
        if (resp[0]) {
            setLoader(1)
            sendForm["vendorType"] = 0
            let resp = await UpdateVendorDetails(sendForm)
            if (resp.status === 1) {
                setLoader(0)
                setSideToster({
                    show: 1,
                    message: 'Data Save',
                    type: 'Success'
                })
                clear()
                setIsUpdate(0)
                navigate("/individualvendor/")
            }
            else {
                setLoader(0)
                setSideToster({
                    show: 1,
                    message: resp.responseValue,
                    type: 'Alert'
                })
            }
        }
        else {
            setSideToster({
                show: 1,
                message: resp[1],
                type: "Alert"
            })
        }
        // console.log("check ", resp)
    }

    let id = useParams()
    useEffect(() => {
        if (id.id !== "null") {
            getVendordata(id.id);
            setIsUpdate(1)
        }
        else {
            setIsUpdate(0)
        }
        // console.log("id", id.id === undefined)
        getData();
    }, [id])
  return (
    <>
    <div className='d-flex flex-column flex-md-row justify-content-between pe-3 ps-3'>
        <PageHeading pagename={"Add Individual Vendor"} />
    </div>

    <div className='container-fluid landformconatiner p-4' id="conatinerId">
        <PageHeading pagename={"Personal Details"} />
        <div className='wrapform pt-3'>
            <div className='d-flex flex-column gap-1'>
                <label className='form-label'>Vendor Name</label>
                <input type='text' className='form-input-box' value={sendForm.name} placeholder='Enter Vendor Name' name='name' onChange={handleChnage} />
            </div>
            <div className='d-flex flex-column gap-1'>
                <label className='form-label'>Email Address</label>
                <input type='email' className='form-input-box' value={sendForm.emailId} placeholder='Enter Email Address' name='emailId' onChange={handleChnage} />
            </div>
            {/* <div className='d-flex flex-column gap-1'>
                <label className='form-label'>Invested in Any Property</label>
                <select className='form-input-box form-select pe-5' name='propteryId' value={sendForm.propteryId} onChange={handleChnage}>
                    <option value="-1">--select Property--</option>
                    {
                        stateList && stateList.map((val, ind) => {
                            return (
                                <option value={val.id}>
                                    {val.stateName}
                                </option>
                            )
                        })
                    }
                </select>
            </div> */}
            <div className='d-flex flex-column gap-1'>
                <label className='form-label'>Mobile No.</label>
                {/* <input type='text' className='form-input-box' value={sendForm.mobileNo} placeholder='Enter Mobile No.' name='mobileNo' onChange={handleChnage} /> */}
                <InputBoxNumber value={sendForm.mobileNo} placeholder='Enter Mobile No.' name='mobileNo' setSendForm={setSendForm} range={10} />

            </div>



            <div className='d-flex flex-column gap-1'>
                <label className='form-label'>Select State</label>
                <select className='form-input-box form-select pe-5' name='stateId' value={sendForm.stateId} onChange={handleChnage}>
                    <option value="-1">--Select State--</option>
                    {
                        stateList && stateList.map((val, ind) => {
                            return (
                                <option value={val.id}>
                                    {val.stateName}
                                </option>
                            )
                        })
                    }
                </select>
            </div>



            <div className='d-flex flex-column gap-1'>
                <label className='form-label'>Select City</label>
                <select className='form-input-box form-select pe-5' name='cityId' value={sendForm.cityId} onChange={handleChnage}>
                    <option value="-1">--Select City--</option>
                    {
                        cityList && cityList.map((val, ind) => {
                            return (
                                <option value={val.id}>
                                    {val.cityName}
                                </option>
                            )
                        })
                    }
                </select>
            </div>

            <div className='d-flex flex-column gap-1'>
                <label className='form-label'>Pin Code</label>
                {/* <input type='text' className='form-input-box' value={sendForm.pincode} placeholder='Enter Pin Code' name='pincode' onChange={handleChnage} /> */}
                <InputBoxNumber value={sendForm.pincode} placeholder='Enter Pin Code' name='pincode' setSendForm={setSendForm} range={10} />

            </div>
            <div className='d-flex flex-column gap-1'>
                <label className='form-label'>Address</label>
                <input type='text' className='form-input-box' value={sendForm.address} placeholder='Enter Address' name='address' onChange={handleChnage} />
            </div>


            {/* <div className='d-flex flex-column gap-1'>
                <label className='form-label'>Unique Id if you have</label>
                <input type='number' className='form-input-box' value={sendForm.mobileNo} placeholder='Enter Unique Id' name='mobileNo' onChange={handleChnage} />
            </div> */}



        </div>
    </div>

    <div className='container-fluid landformconatiner p-4 mt-3' id="conatinerId">
        <PageHeading pagename={"Bank Details"} />
        <div className='wrapform pt-3'>
            <div className='d-flex flex-column gap-1'>
                <label className='form-label'>Select Bank</label>
                <select className='form-input-box form-select pe-5' name='bankId' value={sendForm.vendorBankDetails.bankId} onChange={handleChnage}>
                    <option value="-1">--Select Bank--</option>
                    {
                        bankList && bankList.map((val, ind) => {
                            return (
                                <option value={val.id}>
                                    {val.name}
                                </option>
                            )
                        })
                    }
                </select>
            </div>

            <div className='d-flex flex-column gap-1'>
                <label className='form-label'>Account Holder's Name</label>
                <input type='text' className='form-input-box' value={sendForm.vendorBankDetails.accountHolderName} placeholder='Enter Account Holder Name' name='accountHolderName' onChange={handleChnage} />

            </div>


            <div className='d-flex flex-column gap-1'>
                <label className='form-label'>Branch Code</label>
                <input type='text' className='form-input-box' value={sendForm.vendorBankDetails.branchCode} placeholder='Enter Branch Code' name='branchCode' onChange={handleChnage} />
                {/* <InputBoxAlphaNumarics value={sendForm.vendorBankDetails.branchCode} placeholder='Enter Branch Code' name='branchCode' setSendForm={setSendForm} range={15} /> */}

            </div>
            <div className='d-flex flex-column gap-1 '>
                <label className='form-label'>Bank Account No</label>
                <input type='text' className='form-input-box' value={sendForm.vendorBankDetails.accountNo} placeholder='Enter Bank Account No' name='accountNo' onChange={handleChnage} />
                {/* <InputBoxAlphaNumarics value={sendForm.vendorBankDetails.accountNo} placeholder='Enter Bank Account No' name='accountNo'  setSendForm={setSendForm} range={15} /> */}

            </div>


            <div className='d-flex flex-column gap-1'>
                <label className='form-label'>IFSC Code</label>
                <input type='text' className='form-input-box' value={sendForm.vendorBankDetails.ifscCode} placeholder='Enter IFSC Code' name='ifscCode' onChange={handleChnage} />
                {/* <InputBoxAlphaNumarics value={sendForm.vendorBankDetails.ifscCode} placeholder='Enter IFSC Code' name='ifscCode'  setSendForm={setSendForm} range={15} /> */}
            </div>



        </div>
    </div>



    <div className='container-fluid landformconatiner p-4 mt-4' id="conatinerId">
        <div className='d-flex flex-row justify-content-between '>

            <PageHeading pagename={"Documents"} />
            {/* <span onClick={handleAddImage}>+ Add more</span> */}
        </div>
        {/* {
            allImage && allImage.map((val, ind) => {
                return (
                    <> */}
        <div className='d-flex flex-column gap-1 pt-3 col-4'>
            <label className='form-label'>Select Proof Type</label>
            <select className='form-input-box form-select pe-5' value={documentTypeId} onChange={(e) => { setDocumentTypeId(e.target.value) }}>
                <option value="-1">--Select Proof Type--</option>
                {
                    landDocumentTypeList && landDocumentTypeList.map((val, ind) => {
                        return (
                            <option value={val.id}>{val.documentType}</option>
                        )
                    })
                }
            </select>
        </div>
        <FileUpload setAllImage={setAllImage} allImage={allImage} name="Vendor Image" />
        {/* </>
                )
            })
        } */}

    </div>

    <div className='d-flex flex-row justify-content-md-end pt-2 pb-2'>
        {
            isUpdate === 0 ?

                <button className='btn export-save-btn' onClick={handleSave}>Save Details </button>
                :
                <button className='btn export-save-btn' onClick={handleUpdate}>Update Details </button>

        }
    </div>

    {
        loader === 1 ? <Loader show={loader} /> : ""
    }
    {
        sideToster.show === 1 ?
            <SideMessage close={setSideToster} show={sideToster.show} type={sideToster.type} message={sideToster.message} />
            : ""
    }
</>  )
}
