import React, { useEffect, useState } from 'react'
import SideMessage from '../../../components/SideMessage'
import Loader from '../../../components/Loader'
import InputBoxNumber from '../../../components/InputBoxNumber'
import GetPaymentMaster from '../../../APIs/Master/Payment/GetPaymentMaster'
import GetVendorDetails from '../../../APIs/AddVendor/GetVendorDetails'
import GetExpense from '../../../APIs/Master/Expense/GetExpense'
import GetProject from '../../../APIs/Project/GetProject'
import InsertExpenseDetails from '../../../APIs/AddExpense/InsertExpenseDetails'

export default function ExpwnsesModal(props) {

    let [expenseList, setExpenseList] = useState([])
    let [paymentList, setPaymentList] = useState([])
    let [vendorList, setVendorList] = useState([])
    let [projectList, setProjectList] = useState([])
    let [loader, setLoader] = useState(0)
    let [sideToster, setSideToster] = useState({
        show: 0,
        message: '',
        type: ''
    })

    let [sendForm, setSendForm] = useState(
        {
            "userId": window.userId,
            "ExpenseType": "-1",
            "ReferenceId": "-1",
            "ExpenseId": "-1",
            "PaymentTypeId": "2",
            "VendorId": "-1",

            "Amount": "",
            "PaymentDate": ""


        }
    )
    let handleFileUpload = (e) => {
        if (e.target.files[0] !== "") {
            setSendForm({ ...sendForm, projectExpenseFiles: e.target.files[0] })
        }
    }

    let getData = async () => {
        let expense = await GetExpense()
        let payment = await GetPaymentMaster()
        let vendor = await GetVendorDetails()
        let project = await GetProject()
        if (expense.status === 1) {
            setExpenseList(expense.responseValue)
        }
        if (project.status === 1) {
            setProjectList(project.responseValue)
        }
        if (payment.status === 1) {
            setPaymentList(payment.responseValue)
        }
        if (vendor.status === 1) {
            setVendorList(vendor.responseValue)
        }
    }

    let handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value
        setSendForm({ ...sendForm, [name]: value })
    }

    let handleSaveForm = async () => {
        if (sendForm.ExpenseId !== "-1" && sendForm.PaymentTypeId !== "-1" && sendForm.ExpenseType !== "-1" && sendForm.VendorId !== "-1" && sendForm.Amount !== "" && sendForm.Date !== "" && sendForm.ReferenceId !== "-1") {
            setLoader(1)
            let formData = new FormData()
            Object.keys(sendForm).map((name) => {
                formData.append(name, sendForm[name]);
            })
            let resp = await InsertExpenseDetails(formData)
            if (resp.status === 1) {
                setLoader(0)
                setSideToster({
                    show: 1,
                    message: 'Data Saved',
                    type: 'Success'
                })
                clear()
                // window.location.reload()
                props.closeModal(0)
            }
            else {
                setSideToster({
                    show: 1,
                    message: resp.responseValue,
                    type: 'Success'
                })
                setLoader(0)
            }
        }
        else {
            if (sendForm.Amount === "") {
                setSideToster({
                    show: 1,
                    message: 'Please Enter Amount',
                    type: 'ALert'
                })

            }
            else if (sendForm.ExpenseId === "-1") {

                setSideToster({
                    show: 1,
                    message: 'Please Select Expenses',
                    type: 'ALert'
                })
            }
            else if (sendForm.PaymentTypeId === "-1") {
                setSideToster({
                    show: 1,
                    message: 'Please Select Payment',
                    type: 'ALert'
                })

            }
            else if (sendForm.ExpenseType === "-1") {
                setSideToster({
                    show: 1,
                    message: 'Please Select ExpenseType',
                    type: 'ALert'
                })

            }
            else if (sendForm.ReferenceId === "-1") {
                setSideToster({
                    show: 1,
                    message: 'Please Select Project',
                    type: 'ALert'
                })

            }
            else if (sendForm.VendorId === "-1") {
                setSideToster({
                    show: 1,
                    message: 'Please Select Vendor',
                    type: 'ALert'
                })

            }

            else if (sendForm.Date === "") {
                setSideToster({
                    show: 1,
                    message: 'Please Enter Date',
                    type: 'ALert'
                })

            }
        }

    }

    let handleUpdate = async () => {

        // let resp = await UpdateProjectExpense(sendForm)
        // if (resp.status === 1) {
        //     window.location.reload()
        //     props.closeModal(0)
        // }
    }
    let clear = () => {
        setSendForm(
            {
                "userId": window.userId,
                "ExpenseId": "-1",
                "PaymentTypeId": "-1",
                "Amount": "",
                "Date": ""
            }
        )

    }

    useEffect(() => {
        // if (props.isEdit === 1) {
        //     console.log("dataaaa", props.editData)
        //     let t = {
        //         "userId": window.userId,
        //         "ExpenseId": props.editData.expenseId,
        //         "PaymentTypeId": props.editData.PaymentTypeId,
        //         "Amount": props.editData.amount,
        //         "Date": props.editData.date.split("T")[0],
        //         "id": props.editData.id
        //     }
        //     setTimeout(() => {
        //         setSendForm(t)
        //     }, 600)
        // }
        getData()
    }, [])
    return (
        <>
            <div className={`modal  d-${props.showModal === 0 ? 'none' : 'block'} `} id="addexpenses" tabindex="-1" aria-labelledby="addexpensesLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content pt-3 ps-3 pe-3 pb-1" style={{ maxWidth: "350px" }}>
                        <div className="modal-header">
                            <h1 className="modal-title fs-5 text-primary" id="addexpensesLabel">Add Expenses</h1>
                            <button type="button" className="btn-close" onClick={() => { props.closeModal(0) }} aria-label="Close"></button>
                        </div>
                        <div className="modal-body">

                            <div className='row pb-4'>
                                <div className='col pb-2 pb-md-0'>
                                    <div className='d-flex flex-column gap-1'>
                                        <label className='form-label'>Expenses Type</label>
                                        <select className='form-input-box form-select pe-5' name='ExpenseType' value={sendForm.ExpenseType} onChange={handleChange} >
                                            <option value="-1">--Select Expenses Type--</option>
                                            <option value={1}>Project</option>
                                            <option value={2}>Office</option>

                                        </select>
                                    </div>
                                </div>
                            </div>
                            {

                                sendForm.ExpenseType !== "-1" && sendForm.ExpenseType === "1" ?
                                    <>
                                        <div className='row pb-4'>
                                            <div className='col pb-2 pb-md-0'>
                                                <div className='d-flex flex-column gap-1'>
                                                    <label className='form-label'>Project</label>
                                                    <select className='form-input-box form-select pe-5' name='ReferenceId' value={sendForm.ReferenceId} onChange={handleChange} >
                                                        <option value="-1">--Select Project--</option>
                                                        {
                                                            projectList && projectList.map((val, ind) => {
                                                                return (
                                                                    <option value={val.id}>
                                                                        {val.name}
                                                                    </option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row pb-4'>
                                            <div className='col pb-2 pb-md-0'>
                                                <div className='d-flex flex-column gap-1'>
                                                    <label className='form-label'>Vendor</label>
                                                    <select className='form-input-box form-select pe-5' name='VendorId' value={sendForm.VendorId} onChange={handleChange} >
                                                        <option value="-1">--Select Vendor --</option>
                                                        {/* <option value="0">No Vendor</option> */}
                                                        {
                                                            vendorList && vendorList.map((val, ind) => {
                                                                return (
                                                                    <option value={val.id}>
                                                                        {val.name}
                                                                    </option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </> : ""
                            }



                            <div className='row pb-4'>
                                <div className='col pb-2 pb-md-0'>
                                    <div className='d-flex flex-column gap-1'>
                                        <label className='form-label'>Expense</label>
                                        <select className='form-input-box form-select pe-5' name='ExpenseId' value={sendForm.ExpenseId} onChange={handleChange} >
                                            <option value="-1">--Select Expense--</option>
                                            {
                                                expenseList && expenseList.map((val, ind) => {
                                                    return (
                                                        <option value={val.id}>
                                                            {val.name}
                                                        </option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className='row pb-4'>
                                <div className='col pb-2 pb-md-0'>
                                    <div className='d-flex flex-column gap-1'>
                                        <label className='form-label'>Amount</label>
                                        <InputBoxNumber value={sendForm.Amount} placeholder='Enter Amount' name="Amount" setSendForm={setSendForm} range={10} min={1} max={10000000} />
                                    </div>
                                </div>
                            </div>
                            <div className='row pb-4'>
                                <div className='col pb-2 pb-md-0'>
                                    <div className='d-flex flex-column gap-1'>
                                        <label className='form-label'>Payment Type</label>
                                        <select className='form-input-box form-select pe-5' name='PaymentTypeId' onChange={handleChange} value={sendForm.PaymentTypeId}>
                                            <option value="-1">--Select Payment Type--</option>
                                            {
                                                paymentList && paymentList.map((val, ind) => {
                                                    return (
                                                        <option value={val.id}>
                                                            {val.name}
                                                        </option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className='row pb-4'>
                                <div className='col pb-2 pb-md-0'>
                                    <div className='d-flex flex-column gap-1'>
                                        <label className='form-label'>Date</label>
                                        <input type='date' name="PaymentDate" className='form-input-box' id="PaymentDate" onChange={handleChange} value={sendForm.PaymentDate} />
                                    </div>
                                </div>
                            </div>
                            <div className='row ' >
                                <div className='col pb-2 pb-md-0' >
                                    <div className='d-flex flex-column gap-1' >
                                        <label className='form-label'>Attachment</label>
                                        <div className='d-flex flex-column  align-items-center fileuploadbox pt-2' style={{ maxHeight: "100px", minHeight: "100px" }}>
                                            <i class="bi bi-cloud-upload-fill"></i>
                                            <span className='gardiantext'>Drag & drop your file here</span>
                                            <input type='file' className='fileinput' onChange={handleFileUpload} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer" >

                            {
                                props.isEdit !== 1 ?

                                    <button type="button" style={{ width: "100%" }} className="btn btn-primary" onClick={handleSaveForm} data-bs-dismiss="modal" aria-label="Close">Add Expenses</button>
                                    :
                                    <button type="button" style={{ width: "100%" }} className="btn btn-primary" onClick={handleUpdate} data-bs-dismiss="modal" aria-label="Close">Update Expenses</button>

                            }
                        </div>
                    </div>
                </div>
            </div>
            <Loader show={loader} />

            {
                sideToster.show === 1 ?
                    <SideMessage close={setSideToster} show={sideToster.show} type={sideToster.type} message={sideToster.message} />
                    : ""
            }
        </>
    )
}
