import React from 'react'
import PageHeading from '../../../components/PageHeading'
import Summerybox from '../../../components/Summerybox'
import { useNavigate } from 'react-router-dom'

export default function ProjectPlotSummeryOverview() {
    let navigate = useNavigate()

    return (
        <>
            <div className='container-fluid landformconatiner p-4' style={{ maxHeight: "420px" }}>
                {/* <PageHeading pagename="Project & Plot Summery" /> */}
                <div className='d-flex flex-column flex-md-row justify-content-between pe-3 ps-3 pb-3'>
                    <PageHeading pagename="Project & Plot Summery" />
                    <button className="btn btn-primary" type="button" class="btn btn-primary" onClick={() => { navigate("") }}>+ Create Entry</button>
                </div>
                <div className='expenses-conatiner pt-3' style={{ columnGap: "30px" }}>
                    <Summerybox color="#006CFF" title="Total Project"  number={120} subheading="+ 10% from last month" />
                    <Summerybox color="#F59E0B" title="Total Plots"  number={1500} subheading="+ 10% from last month" />
                    <Summerybox color="#03D4C6" title="Sold Out Plots" number={600} subheading="+ 5% from  last month" />
                    <Summerybox color="#00AFF0" title="Booked Plots" number={300} subheading="+ 5% from  last month" />
                    <Summerybox color="#40BE54" title="Available Plots" number={600} subheading="+ 5% from  last month" />
                </div>
            </div>
        </>
    )
}
