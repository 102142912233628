import './App.css';
import Routing from './Service/Routing';

function App() {

  window.RealestateService = "http://admin.umrahfare.in"
  window.UserService = "http://user.umrahfare.in"
  window.userId = window.sessionStorage.getItem("userLoginData") ? JSON.parse(window.sessionStorage.getItem("userLoginData")).userData.userId : ""
  window.token = window.sessionStorage.getItem("userLoginData") ? "Bearer " + JSON.parse(window.sessionStorage.getItem("userLoginData")).token : ""
  return (
    <Routing />
  );
}

export default App;
