import React, { useEffect, useState } from 'react'
import PageHeading from '../../components/PageHeading'
import FilterTable from '../../components/FilterTable'
import GetStates from '../../APIs/Master/StateMaster/GetStates'
import GetCitiesByStateId from '../../APIs/Master/CityMaster/GetCitiesByStateId'

export default function CityMaster() {
    let [stateList, setStateList] = useState([])
    let [cityList, setCityList] = useState([])
    let [stateName, setStateName] = useState("")
    let [sendForm, setSendForm] = useState(
        {
            "stateId": "-1",
            "cityName": "",
            "userId": window.userId
        }
    )
    let handleChange = (e) => {
        let name = e.target.name
        let value = e.target.value
        console.log("csdcsdcs", name, value)
        if (name === "stateId") {
            getCityData(value)
        }
        setSendForm({ ...sendForm, [name]: value })

    }
    let getData = async () => {
        let respState = await GetStates()
        if (respState.status === 1) {
            setStateList(respState.responseValue)
        }
    }
    let getCityData = async (id) => {
        console.log("fsgfd")
        let respCity = await GetCitiesByStateId(id)
        if (respCity.status === 1) {
            setCityList(respCity.responseValue)
        }
    }

    let handleSave = async() => {
        
    }
    useEffect(() => {
        getData()
    }, [])

    return (
        <div className='row'>
            <PageHeading pagename="City Master" />
            <div className='row container-fluid landformconatiner p-4'>
                <div className='col col-md-4 pb-2_ pb-md-0'>
                    <div className='d-flex flex-column gap-1'>
                        <label className='form-label'>Select State</label>
                        <select className='form-input-box pe-5' onChange={handleChange} value={sendForm.stateId} name='stateId' >
                            <option value="-1">--select State--</option>
                            {
                                stateList && stateList.map((val, ind) => {
                                    return (
                                        <option value={val.id}>
                                            {val.stateName}
                                        </option>
                                    )
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className='col col-md-4 pb-2_ pb-md-0'>
                    <div className='d-flex flex-column gap-1'>
                        <label className='form-label'>City Name</label>
                        <input type='text' name='cityName' placeholder='Enter City Name' onChange={handleChange} value={sendForm.cityName} className='form-input-box' />
                    </div>
                </div>
            </div>
            <div className='row container-fluid landformconatiner p-4 mt-2'>
                <div className='d-flex flex-column flex-md-row justify-content-between'>
                    <PageHeading pagename="City Master List" />
                    <FilterTable />
                </div>
                <div className='pt-4'>
                    <table class="table">
                        <thead>
                            <tr className='table-head'>
                                <th scope="col">#</th>
                                {/* <th scope="col">State</th> */}
                                <th scope="col">City</th>
                                <th scope="col">Edit</th>
                            </tr>
                        </thead>
                        <tbody className='table-body'>
                            {
                                cityList && cityList.map((val, ind) => {
                                    return (
                                        <tr >
                                            <th scope="row">{ind + 1}</th>
                                            {/* <td>{stateName}</td> */}
                                            <td>{val.cityName}</td>
                                            <td></td>
                                        </tr>
                                    )
                                })
                            }


                        </tbody>
                    </table>
                </div>


            </div>
        </div>
    )
}
