export default async function GetLatestInvestorTransactionDetails(referenceId, investorId = "") {
    let url = ``
    if (investorId === "") {
        url = window.RealestateService + `/api/PaymentDetails/GetLatestInvestorTransactionDetails?referenceId=${referenceId}`

    }
    else {
        url = window.RealestateService + `/api/PaymentDetails/GetLatestInvestorTransactionDetails?referenceId=${referenceId}&investorId=${investorId}`

    }
    let head = {
        "content-type": "",
        "accept": ""
    }
    let response = await fetch(url, {
        method: "GET",
        headers: head,
    }).then(res => res.json()).catch(res => res)

    return response
}