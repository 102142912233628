import React, { useState } from 'react'
import PageHeading from '../../../../components/PageHeading'
import { Link } from 'react-router-dom'

export default function SpendByProject() {
    let [projectId, setProjectId] = useState(0)
    let [projectDetails, setProjectDetails] = useState([
        {
            expenseType: "Project Name 1",
            amount: "30120"
        },
        {
            expenseType: "Project Name 2",
            amount: "30120"
        },
        {
            expenseType: "Project Name 3",
            amount: "30120"
        },
        {
            expenseType: "Project Name 4",
            amount: "30120"
        },
    ]);


    return (
        <>
            <div className='container-fluid landformconatiner p-4'>
                <div className='d-flex flex-row justify-content-between'>
                    <PageHeading pagename="Spend By Project" />

                    <div className='d-flex flex-row justify-content-center align-items-center pt-0'>
                        <Link className='text-blue-color pointer' style={{ fontSize: "1rem" }} to={`/addexpenses/${projectId}`}>
                            + Add Expenses
                        </Link>
                    </div>
                </div>

                <div className='d-flex flex-column   mt-2'>

                    {projectDetails.length !== 0 ? projectDetails.map((list) => {
                        return (
                            <div className='d-flex flex-column gap-3 pt-4'>
                                <div className='d-flex flex-row justify-content-between pb-2 pt-2 rowBox' >
                                    <>
                                        <label className='heading-project fw-semibold'>{list.expenseType}</label>
                                        <label className='heading-project fw-semibold'>{"₹" + list.amount}</label>
                                    </>
                                </div>
                            </div>
                        )
                    }) :
                        <div className='d-flex flex-column gap-3 pt-4'>
                            <div className='d-flex flex-row justify-content-between pb-2 pt-2 rowBox' >

                                <label className='heading-project fw-semibold'>No Data Found</label>

                            </div>
                        </div>
                    }
                </div>

            </div>
        </>
    )
}
