import React, { useState } from 'react'
import SideMessage from './SideMessage'

export default function InputBoxAlphaNumarics(props) {
    let [sideToster, setSideToster] = useState({
        show: 0,
        message: '',
        type: ''
    })
    let handleChange = (e) => {
        let name = e.target.name
        let value = e.target.value
        if (e.target.value.length !== 0) {

            if (value.length <= props.range) {
                if (value !== "") {
                    props.setSendForm((data) => ({ ...data, [name]: value }))
                }
                else {
                    // alert("Don't use symbol", e.target.value.length)
                    // setSideToster({
                    //     show: 1,
                    //     message: "Don't use symbol " + e.target.value,
                    //     type: 'Alert'
                    // })
                }
            }
            else {
                setSideToster({
                    show: 1,
                    message: `Only Enter ${props.range} character`,
                    type: 'Alert'
                })
            }
        }
        else {
            props.setSendForm((data) => ({ ...data, [name]: "" }))
        }
    }
    return (
        <>
            <input type="text" className='form-input-box' name={props.name} value={props.value} id={props.id} onChange={handleChange} placeholder={props.placeholder} />
            {
                sideToster.show === 1 ?
                    <SideMessage close={setSideToster} show={sideToster.show} type={sideToster.type} message={sideToster.message} />
                    : ""
            }

        </>
    )
}
