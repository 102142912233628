import React, { useState } from 'react'
import FoodExpenses from "../../../assets/images/FoodExpenses.png"
import TotalExpenses from "../../../assets/images/TotalExpenses.png"
import FuelExpenses from "../../../assets/images/FuelExpenses.png"
import PageHeading from '../../../components/PageHeading'
import AddExpensesModal from '../AddExpensesModal'
import ShowAddExpenses from './ShowAddExpenses'
export default function ExpensesSummery() {
    let [expensesModal, setExpensesModal] = useState(0)

    return (
        <>
            <div className='container-fluid landformconatiner p-4' style={{ maxHeight: "420px" }}>
                <div className='d-flex flex-column flex-md-row justify-content-between pe-3 ps-3'>
                    <PageHeading pagename="Project Summary" />
                    <button className="btn btn-primary" type="button" class="btn btn-primary" onClick={() => { setExpensesModal(1) }}>+ Add Expenses</button>
                </div>

                <div className=' expenses-conatiner mt-4'>
                    <div className='d-flex flex-column gap-3 p-3 expenses-card' style={{ background: "#006CFF" }}>
                        <div className='projecticon'>
                            <img src={TotalExpenses} alt="Total Expenses" width="20px" height="20px" />


                        </div>
                        <div className='d-flex flex-column gap-2'>
                            <span className='proj-heading'>Total Expenses</span>
                            <span className='proj-cost'>₹ 500000000</span>
                        </div>

                    </div>
                    <div className='d-flex flex-column gap-3 p-3 expenses-card' style={{ background: "#03D4C6" }}>
                        <div className='projecticon'>
                            <img src={FuelExpenses} alt="Fuel Expenses" width="20px" height="20px" />
                        </div>
                        <div className='d-flex flex-column gap-2'>
                            <span className='proj-heading'>Fuel Expenses</span>
                            <span className='proj-cost'>₹ 500000000</span>
                        </div>

                    </div>
                    <div className='d-flex flex-column gap-3 p-3 expenses-card' style={{ background: "#00AFF0" }}>
                        <div className='projecticon'>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M2 0C0.895432 0 0 0.895447 0 2V16C0 17.1046 0.895432 18 2 18H16C17.1046 18 18 17.1046 18 16V2C18 0.895447 17.1046 0 16 0H2ZM5 10C5 9.44769 4.55228 9 4 9C3.44772 9 3 9.44769 3 10V14C3 14.5523 3.44772 15 4 15C4.55228 15 5 14.5523 5 14V10ZM9 6C9.55228 6 10 6.44769 10 7V14C10 14.5523 9.55228 15 9 15C8.44772 15 8 14.5523 8 14V7C8 6.44769 8.44772 6 9 6ZM15 4C15 3.44769 14.5523 3 14 3C13.4477 3 13 3.44769 13 4V14C13 14.5523 13.4477 15 14 15C14.5523 15 15 14.5523 15 14V4Z" fill="#00AFF0" />
                            </svg>
                        </div>
                        <div className='d-flex flex-column gap-2'>
                            <span className='proj-heading'>Food Expenses</span>
                            <span className='proj-cost'>₹ 500000000</span>
                        </div>

                    </div>


                    <div className='d-flex flex-column gap-3 p-3 expenses-card' style={{ background: "#3E4095" }}>
                        <div className='projecticon'>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M2 0C0.895432 0 0 0.895447 0 2V16C0 17.1046 0.895432 18 2 18H16C17.1046 18 18 17.1046 18 16V2C18 0.895447 17.1046 0 16 0H2ZM5 10C5 9.44769 4.55228 9 4 9C3.44772 9 3 9.44769 3 10V14C3 14.5523 3.44772 15 4 15C4.55228 15 5 14.5523 5 14V10ZM9 6C9.55228 6 10 6.44769 10 7V14C10 14.5523 9.55228 15 9 15C8.44772 15 8 14.5523 8 14V7C8 6.44769 8.44772 6 9 6ZM15 4C15 3.44769 14.5523 3 14 3C13.4477 3 13 3.44769 13 4V14C13 14.5523 13.4477 15 14 15C14.5523 15 15 14.5523 15 14V4Z" fill="#3E4095" />
                            </svg>
                        </div>
                        <div className='d-flex flex-column gap-2'>
                            <span className='proj-heading'>Transport Expenses</span>
                            <span className='proj-cost'>₹ 500000000</span>
                        </div>

                    </div>
                 
                    <div className='d-flex flex-column gap-3 p-3 expenses-card' style={{ background: "#FE996C" }}>
                        <div className='projecticon'>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M2 0C0.895432 0 0 0.895447 0 2V16C0 17.1046 0.895432 18 2 18H16C17.1046 18 18 17.1046 18 16V2C18 0.895447 17.1046 0 16 0H2ZM5 10C5 9.44769 4.55228 9 4 9C3.44772 9 3 9.44769 3 10V14C3 14.5523 3.44772 15 4 15C4.55228 15 5 14.5523 5 14V10ZM9 6C9.55228 6 10 6.44769 10 7V14C10 14.5523 9.55228 15 9 15C8.44772 15 8 14.5523 8 14V7C8 6.44769 8.44772 6 9 6ZM15 4C15 3.44769 14.5523 3 14 3C13.4477 3 13 3.44769 13 4V14C13 14.5523 13.4477 15 14 15C14.5523 15 15 14.5523 15 14V4Z" fill="#FE996C" />
                            </svg>
                        </div>
                        <div className='d-flex flex-column gap-2'>
                            <span className='proj-heading'>Other Expenses</span>
                            <span className='proj-cost'>₹ 500000000</span>
                        </div>

                    </div>



                </div>


            </div>

            <ShowAddExpenses />
            {
                expensesModal === 1 ? <AddExpensesModal showModal={expensesModal} closeModal={setExpensesModal}  isEdit={0}/> : ""
            }
        </>
    )
}
