import React, { useEffect, useState } from 'react'
import PageHeading from '../../../../components/PageHeading'
import { Link } from 'react-router-dom'
import GetLandOwnerByLandId from '../../../../APIs/Owner/GetLandOwnerByLandId'
import LandOwnerPopUp from '../../../AddLand/ShowLand/LandOwnerPopUp'

export default function LandOwners(props) {

    let [ownerList, setOwnerList] = useState([])
    let [showModal, setShowModal] = useState(0)
    let [editData, setEditData] = useState([])

    let getData = async () => {

        let resp = await GetLandOwnerByLandId(props.landId)
        if (resp.status === 1) {
            setOwnerList(resp.responseValue)
        }

    }
    useEffect(() => {
        if (props.landId !== "") {
            getData()
        }
    }, [props])

    return (
        <>
            <div className='container-fluid landformconatiner p-4'>
                <div className='d-flex flex-row justify-content-between align-items-center'>
                    <PageHeading pagename={`Land Owners  (${ownerList && ownerList.length})`} />
                    <Link to={`/landowners/${props.landId}`} className='text-blue-color fw-semibold'>
                        View All
                    </Link>

                </div>

                <div className='d-flex flex-column gap-3 pt-3'>

                    {
                        ownerList && ownerList.map((val, ind) => {
                            return (
                                // <div className='d-flex flex-row justify-content-between pb-2 rowBox pointer' onClick={() => { setEditData(val); setShowModal(1) }}>
                                <div className='d-flex flex-row justify-content-between pb-2 rowBox pointer' >
                                    <div className='d-flex flex-row gap-2'>
                                        <div style={{ width: "30px", height: "30px", backgroundColor: "#E4E4E4", borderRadius: "15px" }}></div>
                                        <span className='d-flex flex-column gap-1'>
                                            <label className='heading-project fw-semibold'>{val.name}</label>
                                            <label className='sub-heading-project' style={{ color: "#737791" }}>Ownership {val.percentage}%</label>
                                        </span>
                                    </div>
                                    <div className='d-flex flex-row'>
                                        <i class="bi bi-chevron-right"></i>
                                    </div>
                                </div>
                            )
                        })
                    }


                </div>
            </div>
            {
                showModal === 1 ?
                    <LandOwnerPopUp showModal={showModal} closeModal={setShowModal} editData={editData} getData={() => { }} />
                    :
                    ""
            }
        </>
    )
}
