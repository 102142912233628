export default async function GetBankMaster(id) {
    let url = window.RealestateService+  `/api/Bank/GetBankMaster`
    let head = {
        "content-type":"",
        "accept":""
    }
    let response = await fetch(url, {
        method:"GET",
        headers:head,
    }).then(res=>res.json()).catch(res=>res)

    return response
}