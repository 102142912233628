export default async function GetProject(senddata) {
    let url = window.RealestateService + "/api/Project/GetProject"
    let response = {}
    let head = {
        "Content-Type": "application/json",
        'accept': '*/*'
    }
    response = await fetch(url, {
        method: "GET",
        headers: head,
        body: JSON.stringify(senddata)
    }).then(res => res.json()).then(response)
    return response;
}