export default async function UpdateInvestorDetails(sendForm) {
    let url = window.RealestateService+  `/api/Investor/UpdateInvestorDetails`
    let head = {
        'Content-Type': 'application/json-patch+json',
        "accept":"*/*"
    }
    let data ={}
    let response  = await fetch(url, {
        method:"PUT",
        headers:head,
        body:JSON.stringify(sendForm)
    }).then(res=>res.json()).then(data)

    return response
}