import React, { useEffect, useState } from 'react'
import PageHeading from '../../../../components/PageHeading'
import AddExpensesModal from '../../../Expenses/AddExpensesModal'
import { Link } from 'react-router-dom'
import GetProjectExpenseByProject from '../../../../APIs/AddExpense/GetProjectExpenseByProject'

export default function ProjectExpenses() {

    // let [expensesModal, setExpensesModal] = useState(0)
    let [projectId, setProjectId] = useState(0)
    let [expenseDetails, setExpenseDetails] = useState(0);
    let [projectDetails, setProjectDetails] = useState([]);

    let getAllProjectDetails = async (id) => {
        const response = await GetProjectExpenseByProject(id);
        if (response.status === 1) { 
            if(response.responseValue.length !==0){
                setExpenseDetails(response.responseValue[0].totalExpense);
                setProjectDetails(response.responseValue);
            }                     
        }
    }
    useEffect(() => {
        let id =  JSON.parse(window.sessionStorage.getItem("ProjectData")).projectId === undefined?JSON.parse(window.sessionStorage.getItem("ProjectData")).projectData[2]:JSON.parse(window.sessionStorage.getItem("ProjectData")).projectId
        getAllProjectDetails(id);
        setProjectId(id)
    }, [])
    return (
        <>
            <div className='container-fluid landformconatiner p-4'>
                <div className='d-flex flex-row justify-content-between'>
                    <PageHeading pagename="Project Expenses" />
                    <select className='form-input-box pe-5' style={{ height: "35px"}}>
                        <option>
                            Month
                        </option>
                        <option>
                            Year
                        </option>
                        <option>
                            Week
                        </option>
                    </select>

                </div>

                <div className='d-flex flex-column   mt-3'>
                    <div className='d-flex flex-row justify-content-between add-expess'>
                        <div className='d-flex flex-column'>

                            <PageHeading pagename={'₹' + expenseDetails} />

                            {/* <span className='text-blue-color '>
                                - 5% down from last month
                            </span> */}
                        </div>
                        <div className='d-flex flex-row justify-content-center align-items-center pt-0'>
                            <Link className='text-blue-color pointer' style={{ fontSize: "1rem" }} to={`/addexpenses/${projectId}`}>
                                + Add Expenses
                            </Link>
                        </div>
                    </div>

                    {projectDetails.length !==0?  projectDetails.map((list) => {
                         return (
                    <div className='d-flex flex-column gap-3 pt-4'>
                        <div className='d-flex flex-row justify-content-between pb-2 pt-2 rowBox' >
                                    <>
                                        <label className='heading-project fw-semibold'>{list.expenseType}</label>
                                        <label className='heading-project fw-semibold'>{"₹" + list.amount}</label>
                                    </>
                        </div>
                    </div>
                     )
                    }):
                    <div className='d-flex flex-column gap-3 pt-4'>
                    <div className='d-flex flex-row justify-content-between pb-2 pt-2 rowBox' >
                             
                                    <label className='heading-project fw-semibold'>No Data Found</label>
                              
                    </div>
                </div>
                    }
                </div>

            </div>
            {/* {
                expensesModal === 1?<AddExpensesModal showModal={expensesModal} closeModal={setExpensesModal}/>:""
            } */}
        </>
    )
}
