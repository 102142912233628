export default async function GetPaymentMaster(data) {
    let url = window.RealestateService + "/api/PaymentMaster/GetPaymentMaster";
    let head = {
        "content-type":"",
        "accept":""
    }
    let response = await fetch(url, {
        method:"GET",
        headers:head,
    }).then(res=>res.json()).catch(res=>res)

    return response;
}