import React, { useEffect, useRef, useState } from 'react'
import PageHeading from '../../../components/PageHeading';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { Link } from 'react-router-dom';
import GetProject from '../../../APIs/Project/GetProject';
import GetProjectWiseInvestorPaymentDetails from '../../../APIs/Accounting/Investor/GetProjectWiseInvestorPaymentDetails';

export default function InvestorExpenses(props) {
    const chartRef = useRef(null);

    let [investorList, setInvestorList] = useState([])
    let [projectWiseInvestorList, setProjectWiseInvestorList] = useState([])
    let [projectWiseInvestorGraph, setProjectWiseInvestorGraph] = useState([])
    let [totalInvestment, setTotalInvestment] = useState(0)

    let getData = async () => {
        let investorResponse = await GetProject();
        if (investorResponse.status === 1) {
            setInvestorList(investorResponse.responseValue)
        }
    }

    let handleChange = async (e) => {
        let name = e.target.name;
        let value = e.target.value;
        props.setProjectId(value)
        let response = await GetProjectWiseInvestorPaymentDetails(value)
        if (response.status === 1) {
            setProjectWiseInvestorList(response.responseValue)
            let graphData = []
            let inestmentData = 0
            if (response.responseValue.length !== 0) {
                response.responseValue.map((val, ind) => {
                    let temp = { name: val.investorName, y: val.investedAmount, }
                    graphData.push(temp)
                    inestmentData += val.investedAmount
                })
                setProjectWiseInvestorGraph(graphData)
                setTotalInvestment(inestmentData)
            }
            else {
                setProjectWiseInvestorGraph([])
                setTotalInvestment(0)

            }
        }
        else {
            setProjectWiseInvestorGraph([])
            setTotalInvestment(0)
            setProjectWiseInvestorList([])

        }
    }
    useEffect(() => {
        getData()
    }, [])



    const options = {
        chart: {
            type: 'pie',
            margin: [0, 0, 0, 0],
            spacingTop: 0,
            spacingBottom: 0,
            spacingLeft: 0,
            spacingRight: 0,
            height: '250px'
        },
        title: {
            verticalAlign: 'middle',
            floating: true,
            text: "Total Investment <br/>" + (totalInvestment / 10000000).toFixed(2) + "Cr."
        },
        plotOptions: {
            pie: {
                innerSize: '70%',
                size: "100%",
                dataLabels: {
                    enabled: false,
                },
            },
            borderRadius: 600,
        },
        series: [
            {
                name: 'Invested Amount',
                data: projectWiseInvestorGraph,
            },
        ],


    };

    return (
        <div className='container-fluid landformconatiner p-4'>
            <div className='d-flex flex-row justify-content-between'>
                <PageHeading pagename="Invested By Project" />
                <select className='form-input-box pe-5' style={{ height: "35px" }} name="projectId" onChange={handleChange}>
                    {
                        investorList && investorList.map((val, ind) => (
                            <option value={val.id}>{val.name}</option>

                        ))
                    }
                </select>
            </div>

            <div className='row pt-3 mt-2' style={{ height: "295px" }}>
                <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />
            </div>

            <div className='d-flex flex-column gap-3 pt-5 mt-2'>
                {
                    projectWiseInvestorList && projectWiseInvestorList.map((val) =>
                    (
                        <div className='d-flex flex-row justify-content-between pb-2 rowBox' >
                            <div className='d-flex flex-row gap-2'>
                                <div className='projecticon' style={{ backgroundColor: "#3B82F6" }}>
                                    <svg width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M2 0C0.895432 0 0 0.895447 0 2V16C0 17.1046 0.895432 18 2 18H16C17.1046 18 18 17.1046 18 16V2C18 0.895447 17.1046 0 16 0H2ZM5 10C5 9.44769 4.55228 9 4 9C3.44772 9 3 9.44769 3 10V14C3 14.5523 3.44772 15 4 15C4.55228 15 5 14.5523 5 14V10ZM9 6C9.55228 6 10 6.44769 10 7V14C10 14.5523 9.55228 15 9 15C8.44772 15 8 14.5523 8 14V7C8 6.44769 8.44772 6 9 6ZM15 4C15 3.44769 14.5523 3 14 3C13.4477 3 13 3.44769 13 4V14C13 14.5523 13.4477 15 14 15C14.5523 15 15 14.5523 15 14V4Z" fill={"rgba(255, 255, 255, 1)"} />
                                    </svg>

                                </div>
                                <span className='d-flex flex-column '>
                                    <label className='heading-project fw-semibold'>{val.investorName}</label>
                                    <label className='sub-heading-project' >Online Sell</label>
                                </span>
                            </div>
                            <div className='d-flex flex-row'>
                                <PageHeading pagename={`₹ ${(val.investedAmount / 10000000).toFixed(2)}Cr.`} />
                            </div>
                        </div>
                    )
                    )
                }
                {
                    projectWiseInvestorList.length > 5 ?
                        <div className='d-flex flex-row justify-content-between pb-2 ' >
                            <Link to="" style={{ fontWeight: "600", fontSize: "1rem", lineHeight: "24px" }}>View More</Link>
                        </div> : ""
                }

            </div>
        </div>
    )
}
