import React, { useEffect, useState } from 'react'
import FileUpload from '../../../components/FileUpload'
import Loader from '../../../components/Loader'
import SideMessage from '../../../components/SideMessage'
import GetAllGender from '../../../APIs/Master/Gender/GetAllGender'
import GetStates from '../../../APIs/Master/StateMaster/GetStates'
import GetIdentityDocumentTypeList from '../../../APIs/Master/IdentityDocumentTypeMaster/GetIdentityDocumentTypeList'
import GetCitiesByStateId from '../../../APIs/Master/CityMaster/GetCitiesByStateId'
import UpdateLandOwner from '../../../APIs/Owner/UpdateLandOwner'
import InputBoxNumber from '../../../components/InputBoxNumber'
import InputBoxAlphaNumarics from '../../../components/InputBoxAlphaNumarics'

export default function LandOwnerPopUp(props) {
    let [allImage, setAllImage] = useState([])
    let [loader, setLoader] = useState(0)
    let [rawAddress, setRawAddress] = useState([])

    let [sendForm, setSendForm] = useState(
        {
            "landId": JSON.parse(window.sessionStorage.getItem("ProjectData")).landData ? JSON.parse(window.sessionStorage.getItem("ProjectData")).landData.id : "",
            "name": "",
            "age": 0,
            "genderId": 0,
            "mobileNo": "",
            "cityId": 0,
            "stateId": 0,
            "countryId": 0,
            "pincode": "",
            "address": rawAddress,
            "landmark": "",
            "identityTypeId": 0,
            "identityNumber": "",
            "percentage": 0,

        }
    )
    let clearForm = () => {
        setRawAddress([])
        setSendForm({
            "landId": JSON.parse(window.sessionStorage.getItem("ProjectData")).landData ? JSON.parse(window.sessionStorage.getItem("ProjectData")).landData.id : "",
            "name": "",
            "age": 0,
            "genderId": 0,
            "mobileNo": "",
            "cityId": 0,
            "stateId": 0,
            "countryId": 0,
            "pincode": "",
            "address": "",
            "landmark": "",
            "identityTypeId": 0,
            "identityNumber": "",
            "percentage": 0,

        })
        document.getElementsByName("address").value = ""
        setAllImage([])
    }
    let [sideToster, setSideToster] = useState({
        show: 0,
        message: '',
        type: ''
    })
    let [genderList, setGenderList] = useState([])
    let [stateList, setStateList] = useState([])
    let [cityList, setCityList] = useState([])
    let [identityTypeList, setIdentityTypeList] = useState([])

    let getData = async () => {
        let respGender = await GetAllGender()
        let respState = await GetStates()
        let respIdentityType = await GetIdentityDocumentTypeList()
        if (respGender.status === 1) {
            setGenderList(respGender.responseValue)
        }
        if (respState.status === 1) {
            setStateList(respState.responseValue)
        }
        if (respIdentityType.status === 1) {
            setIdentityTypeList(respIdentityType.responseValue)
        }

    }
    let getCity = async (id) => {
        let respCity = await GetCitiesByStateId(id);
        if (respCity.status === 1) {

            setCityList(respCity.responseValue)
        }
    }

    let handleChnage = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setSendForm({ ...sendForm, [name]: value })
        if (name === "stateId") {
            var dropdown = document.getElementsByName(name)[0];
            getCity(value)
            setRawAddress([rawAddress[0], dropdown.options[dropdown.selectedIndex].text, rawAddress[2]])
            setSendForm({ ...sendForm, [name]: value, ["address"]: [rawAddress[0], dropdown.options[dropdown.selectedIndex].text, rawAddress[2]].join(",") })

        }
        else if (name === "cityId") {
            var dropdown = document.getElementsByName(name)[0];
            setRawAddress([rawAddress[0], rawAddress[1], dropdown.options[dropdown.selectedIndex].text, rawAddress[3], rawAddress[4]])
            setSendForm({ ...sendForm, [name]: value, ["address"]: [dropdown.options[dropdown.selectedIndex].text, rawAddress[1], rawAddress[2]].join(",") })

        }
        else if (name === "pincode") {
            setRawAddress([rawAddress[0], rawAddress[1], rawAddress[2], rawAddress[3], value])
            setSendForm({ ...sendForm, [name]: value, ["address"]: [rawAddress[0], rawAddress[1], value].join(",") })

        }
    }

    let handleVerification = () => {
        if (sendForm.name.trim() !== '' && sendForm.age !== 0 && sendForm.genderId !== 0 && sendForm.cityId !== 0 && sendForm.stateId !== 0 && sendForm.pincode.trim() !== '' && sendForm.address.trim() !== '' && sendForm.landmark.trim() !== '' && sendForm.identityTypeId !== 0 && sendForm.identityNumber.trim() !== '' && sendForm.percentage !== 0 && sendForm.mobileNo.trim() !== '') {
            return [true, '']
        }
        else if (sendForm.name.trim() === '') {
            return [false, 'Please Enter Owner Name']

        }
        else if (sendForm.age === 0) {
            return [false, 'Please Enter Age']

        }
        else if (sendForm.genderId === 0) {
            return [false, 'Please Select Gender']

        }
        else if (sendForm.cityId === 0) {
            return [false, 'Please Select City']

        }
        else if (sendForm.stateId === 0) {
            return [false, 'Please Select State']

        }
        else if (sendForm.pincode.trim() === '') {
            return [false, 'Please Enter Pin Code']

        }
        else if (sendForm.landmark.trim() === '') {
            return [false, 'Please Enter KhasraNo']

        }
        else if (sendForm.identityTypeId === 0) {
            return [false, 'Please Enter Pin Code']

        }
        else if (sendForm.address.trim() === '') {
            return [false, 'Please Enter Address']

        }
        else if (sendForm.landmark.trim() === '') {
            return [false, 'Please Enter Landmark']

        }
        else if (sendForm.percentage.trim() === '') {
            return [false, 'Please Enter percentage']

        }
    }

    let handleSave = async () => {
        // console.log("sendform", sendForm)
        // props.handleActiveTab(4)
        let validate = handleVerification()
        if (validate[0]) {
            setLoader(1)
            // let resp = await InsertLandOwner(sendForm)
            let resp = await UpdateLandOwner(sendForm)
            console.log("cdscsdcs", resp)
            if (resp.status === 1) {
                setLoader(0)
                clearForm()
                props.getData()
                props.closeModal(0)

            }
            else {
                setSideToster({
                    show: 1,
                    message: resp.responseValue,
                    type: 'Alert'
                })
                setLoader(0)
            }
        }
        else {
            setSideToster({
                show: 1,
                message: validate[1],
                type: 'Alert'
            })
            setLoader(0)

        }



    }
    useEffect(() => {
        setSendForm(props.editData)
        getCity(props.editData.stateId)

        getData()
    }, [])
    return (
        <>
            <div className={`modal  d-${props.showModal === 0 ? 'none' : 'block'} `} id="addexpenses" tabindex="-1" aria-labelledby="addexpensesLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-xl">
                    <div className="modal-content pt-3 ps-3 pe-3 pb-1" >
                        <div className="modal-header">
                            <h1 className="modal-title fs-5 text-primary" id="addexpensesLabel">Land Owner</h1>
                            <button type="button" className="btn-close" onClick={() => { props.closeModal(0) }} aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className='container-fluid landformconatiner p-4' id="conatinerId">
                                <div className='wrapform'>
                                    <div className='d-flex flex-column gap-1'>
                                        <label className='form-label'>Land Percentage</label>
                                        {/* <input type='number' className='form-input-box' value={sendForm.percentage} placeholder='Enter Land Percentage' name='percentage' onChange={handleChnage} /> */}
                                        <InputBoxNumber name="percentage" value={sendForm.percentage} placeholder='Enter  Land Percentage' setSendForm={setSendForm} range={10} />
                                    </div>
                                    <div className='d-flex flex-column gap-1'>
                                        <label className='form-label'>Owner Name</label>
                                        <input type='text' className='form-input-box' value={sendForm.name} placeholder='Enter Owner Name' name='name' onChange={handleChnage} />
                                    </div>
                                    <div className='d-flex flex-column gap-1'>
                                        <label className='form-label'>Owner Age</label>
                                        {/* <input type='number' className='form-input-box' value={sendForm.age} placeholder='Enter Owner Age' name='age' onChange={handleChnage} /> */}
                                        <InputBoxNumber name="age" value={sendForm.age} placeholder='Enter  Owner Age' setSendForm={setSendForm} range={2} />

                                    </div>
                                    {/* <div className='row pb-4'>
            <div className='col col-md-4 pb-2 pb-md-0'>
              
            </div>
            <div className='col col-md-4 pb-2 pb-md-0'>
              
            </div>
            <div className='col col-md-4 pb-2 pb-md-0'>
             
            </div>
          </div> */}

                                    <div className='d-flex flex-column gap-1'>
                                        <label className='form-label'>Owner Gender</label>
                                        <select className='form-input-box form-select pe-5' value={sendForm.genderId} name='genderId' onChange={handleChnage}>
                                            <option value="-1">--select Gender--</option>
                                            {
                                                genderList && genderList.map((val, ind) => {
                                                    return (
                                                        <option value={val.id}>
                                                            {val.name}
                                                        </option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>

                                    <div className='d-flex flex-column gap-1'>
                                        <label className='form-label'>Owner Mobile No.</label>
                                        {/* <input type='number' className='form-input-box' value={sendForm.mobileNo} placeholder='Enter Mobile No.' name='mobileNo' onChange={handleChnage} /> */}
                                        <InputBoxNumber name="mobileNo" value={sendForm.mobileNo} placeholder='Enter Mobile No.' setSendForm={setSendForm} range={10} />

                                    </div>
                                    <div className='d-flex flex-column gap-1'>
                                        <label className='form-label'>Select State</label>
                                        <select className='form-input-box form-select pe-5' name='stateId' value={sendForm.stateId} onChange={handleChnage}>
                                            <option value="-1">--select State--</option>
                                            {
                                                stateList && stateList.map((val, ind) => {
                                                    return (
                                                        <option value={val.id}>
                                                            {val.stateName}
                                                        </option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>

                                    {/* <div className='row pb-4'>
            <div className='col col-md-4 pb-2 pb-md-0'>
             
            </div>
            <div className='col col-md-4 pb-2 pb-md-0'>
              
            </div>

            <div className='col col-md-4 pb-2 pb-md-0'>
              
            </div>
          </div> */}

                                    <div className='d-flex flex-column gap-1'>
                                        <label className='form-label'>Select City</label>
                                        <select className='form-input-box form-select pe-5' name='cityId' value={sendForm.cityId} onChange={handleChnage}>
                                            <option value="-1">--select City--</option>
                                            {
                                                cityList && cityList.map((val, ind) => {
                                                    return (
                                                        <option value={val.id}>
                                                            {val.cityName}
                                                        </option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>

                                    <div className='d-flex flex-column gap-1'>
                                        <label className='form-label'>Pin Code</label>
                                        {/* <input type='number' className='form-input-box' value={sendForm.pincode} placeholder='Enter Pin Code' name='pincode' onChange={handleChnage} /> */}
                                        <InputBoxNumber name="pincode" value={sendForm.pincode} placeholder='Enter Pin Code' setSendForm={setSendForm} range={10} />

                                    </div>
                                    <div className='d-flex flex-column gap-1'>
                                        <label className='form-label'>Land Mark</label>
                                        <input type='text' className='form-input-box' value={sendForm.landmark} placeholder='Enter Land Mark' name='landmark' onChange={handleChnage} />
                                    </div>
                                    {/* <div className='row pb-4'>
            <div className='col col-md-4 pb-2 pb-md-0'>
              
            </div>
            <div className='col col-md-4 pb-2 pb-md-0'>
             
            </div>
            <div className='col col-md-4 pb-2 pb-md-0'>
              
            </div>
          </div> */}



                                    <div className='d-flex flex-column gap-1'>
                                        <label className='form-label'>Select Identity Type</label>
                                        <select className='form-input-box form-select pe-5' name='identityTypeId' value={sendForm.identityTypeId} onChange={handleChnage}>
                                            <option value="-1">--select Identity Type--</option>
                                            {
                                                identityTypeList && identityTypeList.map((val, ind) => {
                                                    return (
                                                        <option value={val.id}>
                                                            {val.documentType}
                                                        </option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className='d-flex flex-column gap-1'>
                                        <label className='form-label'>Owner Identity No.</label>
                                        {/* <input type='number' className='form-input-box' placeholder='Enter Identity No.' value={sendForm.identityNumber} name='identityNumber' onChange={handleChnage} /> */}
                                        <InputBoxAlphaNumarics name="identityNumber" value={sendForm.pincode} placeholder='Enter Identity No.' setSendForm={setSendForm} range={15} />

                                    </div>
                                    {/* <div className='row pb-4'>
            <div className='col col-md-4 pb-2 pb-md-0'>
              
            </div>
            <div className='col col-md-4 pb-2 pb-md-0'>
              
            </div>
            <div className='col col-md-4 pb-2 pb-md-0'>
              
            </div>

          </div> */}
                                </div>

                                <div className='row mt-3'>
                                    <div className='d-flex flex-column gap-1'>
                                        <label className='form-label'>Address</label>
                                        <textarea className='form-input-box' placeholder='Enter Address' value={sendForm.address} name='address' onChange={handleChnage}></textarea>
                                        {/* <input type='text' className='form-input-box' /> */}
                                    </div>
                                </div>
                                <FileUpload setAllImage={setAllImage} allImage={allImage} />
                            </div>
                            <div className='d-flex flex-row justify-content-md-end'>
                                <button className='btn export-save-btn' onClick={handleSave}>Update Owner</button>
                            </div>

                        </div>
                    </div>
                </div>
                {
                    sideToster.show === 1 ?
                        <SideMessage handleShow={setSideToster} show={sideToster.show} tosterType={sideToster.type} message={sideToster.message} />
                        : ""
                }
            </div>


            <Loader show={loader} />

        </>
    )
}
