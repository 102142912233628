export default async function DeleteLandOwner(id) {
    let url = window.RealestateService+  `/api/Land/DeleteLandOwner?id=${id}`
    let head = {
        "content-type":"",
        "accept":""
    }
    let response = await fetch(url, {
        method:"DELETE",
        headers:head,
        body:JSON.stringify("")
    }).then(res=>res.json()).catch(res=>res)

    return response
}