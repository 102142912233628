import React from 'react'
import PageHeading from '../../../../components/PageHeading'

export default function RecentTransaction() {
    return (
        <div className='container-fluid landformconatiner p-4'>
            <div className='d-flex flex-row justify-content-between align-items-center'>
                <PageHeading pagename="Recent Transaction" />
                <span className='text-blue-color fw-semibold'>
                    View All
                </span>

            </div>

            <div className='d-flex flex-column gap-3 pt-3'>
                <div className='d-flex flex-row justify-content-between pb-2 rowBox' >
                    <div className='d-flex flex-row gap-2'>
                        <div style={{ width: "30px", height: "30px", backgroundColor: "#E4E4E4", borderRadius: "15px" }}></div>
                        <span className='d-flex flex-column gap-2'>
                            <label className='heading-project fw-semibold'>Rehan Huain</label>
                            <label className='sub-heading-project'>10 Nov 2023, 6:45 PM </label>
                            <label className='sub-heading-project2' >Remaining ₹50000</label>
                        </span>
                    </div>
                    <div className='d-flex flex-row'>
                        <PageHeading pagename="₹ 50Cr." />
                    </div>
                </div>
                <div className='d-flex flex-row justify-content-between  pb-2 rowBox' >
                    <div className='d-flex flex-row gap-2'>
                        <div style={{ width: "30px", height: "30px", backgroundColor: "#03D4C6", borderRadius: "15px" }}></div>
                        <span className='d-flex flex-column gap-1'>
                            <label className='heading-project fw-semibold'>Ashif Iqbal</label>
                            <label className='sub-heading-project'>10 Nov 2023, 6:45 PM </label>
                            <label className='sub-heading-project2' >Remaining ₹50000</label>

                        </span>
                    </div>
                    <div className='d-flex flex-row'>
                        <PageHeading pagename="₹ 25Cr." />
                    </div>
                </div>
                <div className='d-flex flex-row justify-content-between  pb-2 rowBox' >
                    <div className='d-flex flex-row gap-2'>
                        <div style={{ width: "30px", height: "30px", backgroundColor: "#006CFF", borderRadius: "15px" }}></div>
                        <span className='d-flex flex-column gap-1'>
                            <label className='heading-project fw-semibold'>Sale Area</label>
                            <label className='sub-heading-project'>10 Nov 2023, 6:45 PM </label>
                            <label className='sub-heading-project2' >Remaining ₹50000</label>

                        </span>
                        <span>

                        </span>
                    </div>
                    <div className='d-flex flex-row'>
                        <PageHeading pagename="₹ 30Cr." />
                    </div>
                </div>
                <div className='d-flex flex-row justify-content-between pb-2 rowBox' >
                    <div className='d-flex flex-row gap-2'>
                        <div style={{width: "30px", height: "30px", backgroundColor: "#FE996C", borderRadius: "15px" }}></div>
                        <span className='d-flex flex-column gap-1'>
                            <label className='heading-project fw-semibold'>Ashif Iqbal</label>
                            <label className='sub-heading-project'>10 Nov 2023, 6:45 PM </label>
                            <label className='sub-heading-project2' >Remaining ₹50000</label>

                        </span>
                    </div>
                    <div className='d-flex flex-row'>
                        <PageHeading pagename="₹ 10Cr." />
                    </div>
                </div>
            </div>
        </div>
    )
}
