export default async function UpdateLandReferenceDocument(sendForm, id) {
    let url = window.RealestateService+  `/api/Land/UpdateLandReferenceDocument?LandId=${id}`
    let head = {
        // "content-type":"multipart/form-data",
        "accept":"*/*"
    }
    let data ={}
    let response  = await fetch(url, {
        method:"PUT",
        headers:head,
        body:sendForm
    }).then(res=>res.json()).then(data)

    return response
}