import React, { useState } from 'react'
import PageHeading from '../../../components/PageHeading'
import FileUpload from '../../../components/FileUpload'
import InsertPaymentDetails from '../../../APIs/PaymentDetails/InsertPaymentDetails'
import Loader from '../../../components/Loader'
import SideMessage from '../../../components/SideMessage'

const SendAmount = ({investorName,investorId, typeId, pendingAmount}) => {
  let [deletedImages, setDeletedImages] = useState([])
  let [allImage, setAllImage] = useState([])
  let [loader, setLoader] = useState(0)

  const [sendForm, setSendForm] = useState({
    payeeTypeId: typeId,
    referenceId: investorId,
    transType: 0,
    paidTo: 0,
    amount: 0,
    paymentTypeId: 0,
    paymentDate: 0,
    remark: '',
    createdBy: 0,
  })
  let [sideToster, setSideToster] = useState({
    show: 0,
    message: '',
    type: ''
  })
  const handleChange = (e) => {
    const { name, value } = e.target;
    setSendForm((prev) => ({
      ...prev,
      [name]: value
    }))
  }

  let clear = () => {
    setSendForm({
      payeeTypeId: 0,
      referenceId: 0,
      transType: 0,
      paidTo: 0,
      amount: 0,
      paymentTypeId: 0,
      paymentDate: 0,
      remark: '',
    })
  }
  const handleSave = async () => {
    const response = await InsertPaymentDetails(sendForm);
    setLoader(1)
    if (response.status === 1) {
      setLoader(0)
      setSideToster({
        show: 1,
        message: 'Data Saved',
        type: 'Success'
      })
      clear()
    }
    else {
      setLoader(0)
      setSideToster({
        show: 1,
        message: response.responseValue,
        type: 'Alert'
      })
    }
  }
  return (
    <>
      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <PageHeading pagename="Send Amount" />
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div className="row">
                <div className="row container-fluid landformconatinermaster_ p-4">
                  <div className="col col-md-4 pb-2_ pb-md-0">
                    <div className="d-flex flex-column gap-1">
                      <label className="form-label">Send To</label>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        value={investorName && investorName}
                        disabled
                        // placeholder="Enter expense name"
                        onChange={handleChange}
                        // value={sendForm.paidTo}
                        className="form-input-box"
                      />
                      <small
                        id="errExpense"
                        className="form-text text-danger"
                        style={{ display: "none" }}
                      ></small>
                    </div>
                  </div>
                  {/* <div className="col col-md-4 pb-2 pb-md-0">
          <div className="d-flex flex-column gap-1">
              <label className="form-label">Hold amount</label>
              <input
                type="text"
                name="name"
                id="name"
                placeholder="Enter expense name"
               // onChange={handleChange}
                //value={sendForm.name}
                className="form-input-box"
              />
              <small
                id="errExpense"
                className="form-text text-danger"
                style={{ display: "none" }}
              ></small>
            </div>
            </div> */}
                  {/* <div className="col col-md-4 pb-2_ pb-md-0">
          <div className="d-flex flex-column gap-1">
              <label className="form-label">Land Area</label>
              <input
                type="text"
                name="name"
                id="name"
                placeholder="Enter expense name"
               // onChange={handleChange}
                //value={sendForm.name}
                className="form-input-box"
              />
              <small
                id="errExpense"
                className="form-text text-danger"
                style={{ display: "none" }}
              ></small>
            </div>
            </div> */}

                  <div className="col col-md-4 pb-md-0">
                    <div className="d-flex flex-column gap-1">
                      <label className="form-label">Pending Amount</label>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        // placeholder="Enter expense name"
                        disabled
                        onChange={handleChange}
                        value={pendingAmount}
                        className="form-input-box"
                      />
                      <small
                        id="errExpense"
                        className="form-text text-danger"
                        style={{ display: "none" }}
                      ></small>
                    </div>
                  </div>
                  <div className="col col-md-4 pb-md-0">
                    <div className="d-flex flex-column gap-1">
                      <label className="form-label">Account No</label>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Enter Account No."
                        onChange={handleChange}
                        // value={sendForm.}
                        className="form-input-box"
                      />
                      <small
                        id="errExpense"
                        className="form-text text-danger"
                        style={{ display: "none" }}
                      ></small>
                    </div>
                  </div>
                  {/* <div className="col col-md-4 pt-4 pb-md-0">
                 <div className="d-flex flex-column gap-1">
              <label className="form-label">Per Square Fit</label>
              <input
                type="text"
                name="name"
                id="name"
                placeholder="Enter expense name"
               // onChange={handleChange}
                //value={sendForm.name}
                className="form-input-box"
              />
              <small
                id="errExpense"
                className="form-text text-danger"
                style={{ display: "none" }}
              ></small>
            </div>
            </div> */}

                  <div className="col col-md-4 pt-4 pb-md-0">
                    <div className="d-flex flex-column gap-1">
                      <label className="form-label">Date</label>
                      <input
                        type="date"
                        name="paymentDate"
                        id="paymentDate"
                        placeholder="Enter expense name"
                        onChange={handleChange}
                        value={sendForm.paymentDate}
                        className="form-input-box"
                      />
                      <small
                        id="errExpense"
                        className="form-text text-danger"
                        style={{ display: "none" }}
                      ></small>
                    </div>
                  </div>
                  {/* <div className="col col-md-4 pt-4 pb-md-0">
                 <div className="d-flex flex-column gap-1">
              <label className="form-label">Area</label>
              <input
                type="text"
                name="name"
                id="name"
                placeholder="Enter expense name"
               // onChange={handleChange}
                //value={sendForm.name}
                className="form-input-box"
              />
              <small
                id="errExpense"
                className="form-text text-danger"
                style={{ display: "none" }}
              ></small>
            </div>
            </div> */}
                  <div className="col col-md-4 pt-4 pb-md-0">
                    <div className="d-flex flex-column gap-1">
                      <label className="form-label">Amount</label>
                      <input
                        type="text"
                        name="amount"
                        id="amount"
                        placeholder="Enter Amount"
                        onChange={handleChange}
                        value={sendForm.amount}
                        className="form-input-box"
                      />
                      <small
                        id="errExpense"
                        className="form-text text-danger"
                        style={{ display: "none" }}
                      ></small>
                    </div>
                  </div>
                  <div className="col col-md-4 pt-4 pb-md-0">
                    <div className="d-flex flex-column gap-1">
                      <label className="form-label">Remark</label>
                      <input
                        type="text"
                        name="remark"
                        id="remark"
                        placeholder="Enter Remark"
                        onChange={handleChange}
                        value={sendForm.remark}
                        className="form-input-box"
                      />
                      <small
                        id="errExpense"
                        className="form-text text-danger"
                        style={{ display: "none" }}
                      ></small>
                    </div>
                  </div>
                  <div className="col ol-md-12  pb-md-0">
                    <FileUpload setDeletedImages={setDeletedImages} deletedImages={deletedImages} setAllImage={setAllImage} allImage={allImage} name="Add Attachments" />
                    <sapn className="text-danger">Before clicking send button please review all the details very care fully</sapn><br />
                    <span class="btn btn-primary mt-2" onClick={handleSave}>Confirm and Send</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {
        loader === 1 ? <Loader show={loader} /> : ""
      }

    </>
  )
}

export default SendAmount