export default async function DeleteProjectExpense(id) {
    let url = window.RealestateService+  `/api/ProjectExpense/DeleteProjectExpense?id=${id}`
    let head = {
        "content-type":"",
        "accept":""
    }
    let response = await fetch(url, {
        method:"DELETE",
        headers:head,
    }).then(res=>res.json()).catch(res=>res)

    return response
}