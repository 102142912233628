import React from 'react'
import PageHeading from '../../../../components/PageHeading'
import Summerybox from '../../../../components/Summerybox'

export default function FinancialOverview() {
    return (
        <div className='container-fluid landformconatiner p-4' style={{ maxHeight: "420px" }}>
            <PageHeading pagename="Financial Overview" />
           
            <div className='expenses-conatiner' >
                <Summerybox color="#006CFF" title="Total Sales" money={20000} subheading="+ 10% from last month"/>
                <Summerybox color="#03D4C6" title="Total Expenses" money={80000} subheading="+5% from last month"/>
                <Summerybox color="#FE996C" title="Net Profit" money={40000} subheading="- 8% from  last month"/>
            </div>
        </div>
    )
}
