import React, { useState } from 'react'
import PageHeading from '../../../components/PageHeading'
import Summerybox from '../../../components/Summerybox'
import { useNavigate } from 'react-router-dom'
import ExpwnsesModal from './ExpwnsesModal'

export default function ExpensesOverview({ expensesData }) {
  let navigate = useNavigate()
  let [showModal, setShowModal] = useState(0)
  let handleClick = () => { }
  return (
    <>
      <div className='container-fluid landformconatiner p-4' style={{ maxHeight: "420px" }}>
        {/* <PageHeading pagename="Expenses Overview" /> */}
        <div className='d-flex flex-column flex-md-row justify-content-between pe-3 ps-3 pb-3'>
          <PageHeading pagename="Expenses Overview" />
          <button className="btn btn-primary" type="button" class="btn btn-primary" onClick={() => {setShowModal(1) }}>+ Add Expenses</button>
        </div>
        <div className='expenses-conatiner' style={{ columnGap: "25px" }}>



          <Summerybox color="#006CFF" title="Office Expenses" money={expensesData.officeExpense} subheading="" />
          <Summerybox color="#03D4C6" title="Project Expenses" money={expensesData.projectExpense} subheading="" />
          <Summerybox color="#FE996C" title="Pending Request" money={expensesData.pending} subheading="" />
        </div>
        {/* + 10% from last month
        +5% from last month
        - 8% from  last month */}
      </div>
      <ExpwnsesModal isEdit={0} showModal={showModal} closeModal={setShowModal}/>
    </>
  )
}
