import React from 'react'

export default function Summerybox(props) {
    return (
        <div className='d-flex flex-column gap-3 p-3 expenses-card' style={{ background: props.color }}>
            <div className='projecticon'>
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2 0C0.895432 0 0 0.895447 0 2V16C0 17.1046 0.895432 18 2 18H16C17.1046 18 18 17.1046 18 16V2C18 0.895447 17.1046 0 16 0H2ZM5 10C5 9.44769 4.55228 9 4 9C3.44772 9 3 9.44769 3 10V14C3 14.5523 3.44772 15 4 15C4.55228 15 5 14.5523 5 14V10ZM9 6C9.55228 6 10 6.44769 10 7V14C10 14.5523 9.55228 15 9 15C8.44772 15 8 14.5523 8 14V7C8 6.44769 8.44772 6 9 6ZM15 4C15 3.44769 14.5523 3 14 3C13.4477 3 13 3.44769 13 4V14C13 14.5523 13.4477 15 14 15C14.5523 15 15 14.5523 15 14V4Z" fill={props.color} />
                </svg>
            </div>
            <div className='d-flex flex-column gap-2'>
                {
                    props.money !== undefined ?
                        <span span className='proj-cost'>&#8377; {props.money}</span>
                        :
                        <span className='proj-cost'>{props.number}</span>

                }
                <span className='proj-heading'>{props.title}</span>
                <span className='proj-subheading'>{props.subheading}</span>
            </div>

        </div >
    )
}
