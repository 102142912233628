import React, { useEffect, useState } from 'react'
import ExpensesOverview from './ExpensesOverview'
import CompareBarGraph from '../../../components/CompareBarGraph'
import LatestExpensesTable from './LatestExpensesTable'
import Expenses from './Expenses'
import GetOverallProjectExpenses from '../../../APIs/AddExpense/GetOverallProjectExpenses'

export default function ExpensesOverviewIndex() {

    let [salePurchase, setSalePurchase] = useState(["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July"])
    let [salePurchaseData, setSalePurchaseData] = useState([])
    let [expensesType, setExpensesType] = useState(1)
    let [expensesData, setExpensesData] = useState([])
    let getData = async () => {
        let response = await GetOverallProjectExpenses(expensesType)
        if (response.status === 1) {
            setExpensesData(response.responseValue)
        }
        else {

        }
    }
    useEffect(() => {
        setSalePurchaseData([
            {
                label: 'One',
                barPercentage: 0.3,
                backgroundColor: '#E3E4ED',
                data: [0, 1, 3, 0, 2, 0, 0, 2, 0, 1, 0, 1, 1, 0, 0, 1, 0, 0, 2, 1, 0, 1, 2, 1, 1, 0, 0, 0, 2, 2, 0, 3],
                barThickness: 10

            }, {
                label: 'Two',
                barPercentage: 0.3,
                backgroundColor: '#3E4095',
                data: [0, 0, 1, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 1],
                barThickness: 10

            },

        ])
        getData()

    }, [expensesType])

    return (
        <>
            <div className='row pb-3'>
                <div className='col col-md-8'>
                    <div className='d-flex flex-column gap-3'>
                        <ExpensesOverview expensesData={expensesData}/>
                        <CompareBarGraph title="Expenses Activity" datalabel={salePurchase} dataArray={salePurchaseData} yLabel="Price" xLabel="Months" />
                    </div>
                </div>
                <div className='col col-md-4'>
                    <div className='d-flex flex-column gap-3' >
                        <Expenses expensesData={expensesData.expenseById} setExpensesType={setExpensesType}/>
                    </div>
                </div>
            </div>
            <div className='row ps-3 pe-3 '>
                <LatestExpensesTable expensesData={expensesData.recentTransactions}/>
            </div>
        </>
    )
}
