import React, { useRef, useState } from 'react'

export default function FilterTable(props) {
    let [searchValue, setSearchValue] = useState("")

    let searchBySearch = (searchValue) => {
        if(props.originalList.length !== 0)
        {
            const matchResult = props.originalList.filter(obj => Object.values(obj).toString().toLowerCase().trim().replaceAll(" ", "").includes(searchValue.toLowerCase().trim().replaceAll("", "")))
            if (matchResult.length !== 0) {
                props.tempListMethod(matchResult)
            }
            else {
                props.tempListMethod([])
            }
        }
        else{
            props.tempListMethod([])

        }
       



    }
    return (
        <div className='d-flex flex-column gap-2 flex-md-row'>
            {
                props.isSearch === true ?
                    <div className="searchbox" role="search">
                        <input className="form-control me-2" type="search" onChange={(e) => { searchBySearch(e.target.value) }} placeholder="Search" aria-label="Search" style={{ width: "150px" }} />
                        <i class="bi bi-search search-icon"></i>
                    </div> : ""
            }

            {
                props.isdate === true ?
                    <input type='date' name='date' onChange={""} className='form-input-box' style={{ height: "45px", width: "150px" }} />
                    : ""
            }

            {
                props.isFilter === true ?
                    <div className="filterbox" role="search">
                        {/* <input className="form-control me-2" type="none" placeholder="Filter" aria-label="Search"  /> */}
                        <div className="form-control me-2" type="none" placeholder="Filter" aria-label="Search">
                            <i class="bi bi-filter-left fs-3"></i>
                        </div>
                        <i class="bi bi-funnel filter-icon"></i>
                    </div> : ""
            }
            {
                props.isExport === true ?

                    <button className='btn export-save-btn' onClick={""}>Export .CSV</button> : ""
            }

        </div>
    )
}
