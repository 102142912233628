import React, { useEffect, useState } from 'react'
import FinancialOverview from './Left/FinancialOverview'
import CompareBarGraph from '../../../components/CompareBarGraph'
import RevenueSummery from './Right/RevenueSummery'

export default function AccountDashboardIndex() {

  let [salePurchase, setSalePurchase] = useState(["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July"])
  let [salePurchaseData, setSalePurchaseData] = useState([])
  let [investmentData, setInvestmentData] = useState([])
  let [landOwnerData, setLandOwnerData] = useState([])

  useEffect(() => {
    setSalePurchaseData([
      {
        label: 'One',
        backgroundColor: 'rgba(62, 64, 149, 1)',
        data: [0, 1, 3, 0, 2, 0, 0, 2, 0, 1, 0, 1, 1, 0, 0, 1, 0, 0, 2, 1, 0, 1, 2, 1, 1, 0, 0, 0, 2, 2, 0, 3],
        barThickness: 10

      }, {
        label: 'Two',
        backgroundColor: 'rgba(227, 228, 237, 1)',
        data: [0, 0, 1, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 1],
        barThickness: 10

      },
      // {
      //   label: 'Three',
      //   backgroundColor: 'rgba(62, 64, 149, 1)',
      //   data: [0, 0, 1, 0, 0, 0, 0, 0, 1, 1, 0, 1, 0, 0, 1, 0, 0, 0, 1, 1, 0, 1, 2, 0, 0, 0, 1, 0, 0, 0, 0, 1]
      // }
    ])

    setInvestmentData([
      {
        label: 'Sample Data',
        data: [32, 85, 45, 74, 20, 54, 99],
        backgroundColor: 'rgba(62, 64, 149, 1)',
        borderColor: 'rgba(62, 64, 149, 1)',
        borderWidth: 1,
        barThickness: 20
      },
    ])
    setLandOwnerData([
      {
        label: 'Sample Data',
        data: [25, 75, 45, 65, 30, 44, 80],
        backgroundColor: 'rgba(62, 64, 149, 1)',
        borderColor: 'rgba(62, 64, 149, 1)',
        borderWidth: 1,
        barThickness: 20
      },
    ])
  }, [])

  return (
    <div className='row pb-3'>
      <div className='col col-md-8'>
        <div className='d-flex flex-column gap-3'>
          <FinancialOverview />
          <CompareBarGraph title="Sales & Purchases" datalabel={salePurchase} dataArray={salePurchaseData} yLabel="Price" xLabel="Months" />
          <CompareBarGraph title="Investment Summery" datalabel={salePurchase} dataArray={investmentData} yLabel="Price" xLabel="Months" />
          <CompareBarGraph title="Land Owner Summry" datalabel={salePurchase} dataArray={landOwnerData} yLabel="Price" xLabel="Months" />

        </div>
      </div>
      <div className='col col-md-4'>
        <div className='d-flex flex-column gap-3' >
          <RevenueSummery />
        </div>
      </div>
    </div>
  )
}
